import { RestApi } from "lisa/common/adapters/apicoreapi/api";

if (!process.env.REACT_APP_APICORE_URL) {
  throw new Error("REACT_APP_APICORE_URL is not defined");
}

const apicoreApi = new RestApi({
  url: String(process.env.REACT_APP_APICORE_URL),
});

// ---------------------------------------------------------------------
if (!process.env.REACT_APP_MEDIA_API_URL) {
  throw new Error("REACT_APP_MEDIA_API_URL is not defined");
}

const mediaApi = new RestApi({
  url: String(process.env.REACT_APP_MEDIA_API_URL),
}, true);

export {
  //
  RestApi,
  apicoreApi,
  mediaApi
};
