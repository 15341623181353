import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, loading, success } from "lisa/common/store/Loadable";
import { initialState } from "./CheckoutStoreState";
import { CheckoutProduct } from "../models/CheckoutProduct";

function updateProduct(state: CheckoutProduct[], product: CheckoutProduct): CheckoutProduct[] {
  const index = state.findIndex((item) => item.productId === product.productId);
  if (index !== -1) {
    state[index].amount = product.amount;
  } else {
    state.push(product);
  }
  return state;
}

// For lisa 2.1.0 we will push/remove each product separately as we've updated the cart UI
function removeProductById(state: CheckoutProduct[], productId: string): CheckoutProduct[] {
  const index = state.findIndex(product => product.productId === productId);
  if (index === -1) return state; // No match found, return original array

  return [...state.slice(0, index), ...state.slice(index + 1)];
}

function updateProductAmountById(
  productId: string,
  state: CheckoutProduct[],
  updatedAmount: number
): CheckoutProduct[] {
  const index = state.findIndex((product) => product.productId === productId);
  if (index !== -1) {
    if (updatedAmount > 0) {
      state[index].amount = updatedAmount;
    } else {
      state.splice(index, 1);
    }
  }
  return state;
}


const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    // Add Product
    addProduct(state) {
      state.checkoutProducts = loading<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    addProductSuccess(state, action: PayloadAction<{ checkoutProduct: CheckoutProduct }>) {
      state.checkoutProducts.data = updateProduct(state.checkoutProducts.data, action.payload.checkoutProduct);
      state.checkoutProducts = success<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    addProductError(state, action: PayloadAction<UIErrorCompact>) {
      state.checkoutProducts = error<CheckoutProduct[]>(state.checkoutProducts.data, action.payload);
    },

    // Remove Product
    removeProduct(state) {
      state.checkoutProducts = loading<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    removeProductSuccess(state, action: PayloadAction<{ productId: string }>) {
      state.checkoutProducts.data = removeProductById(state.checkoutProducts.data, action.payload.productId);
      state.checkoutProducts = success<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    removeProductError(state, action: PayloadAction<UIErrorCompact>) {
      state.checkoutProducts = error<CheckoutProduct[]>(state.checkoutProducts.data, action.payload);
    },

    // Get All Products
    getAllCheckoutProducts(state) {
      state.checkoutProducts = loading<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    getAllCheckoutProductsSuccess(state, action: PayloadAction<{ products: CheckoutProduct[] }>) {
      state.checkoutProducts = success<CheckoutProduct[]>(action.payload.products);
    },
    getAllProductToCheckoutError(state, action: PayloadAction<UIErrorCompact>) {
      state.checkoutProducts = error<CheckoutProduct[]>(state.checkoutProducts.data, action.payload);
    },

    // Update Product Amount
    updateProductAmount(state) {
      state.checkoutProducts = loading<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    updateProductAmountSuccess(
      state,
      action: PayloadAction<{ productId: string; updatedAmount: number }>
    ) {
      state.checkoutProducts.data = updateProductAmountById(
        action.payload.productId,
        state.checkoutProducts.data,
        action.payload.updatedAmount
      );
      state.checkoutProducts = success<CheckoutProduct[]>(state.checkoutProducts.data);
    },
    updateProductAmountError(state, action: PayloadAction<UIErrorCompact>) {
      state.checkoutProducts = error<CheckoutProduct[]>(state.checkoutProducts.data, action.payload);
    },
  },
});

export const {
  addProduct,
  addProductSuccess,
  addProductError,
  removeProduct,
  removeProductSuccess,
  removeProductError,
  getAllCheckoutProducts,
  getAllCheckoutProductsSuccess,
  getAllProductToCheckoutError,
  updateProductAmount,
  updateProductAmountSuccess,
  updateProductAmountError,
} = feedSlice.actions;

export default feedSlice.reducer;