import { Loadable, loading } from "../../../../common/store/Loadable";
import { ListingList } from "../adapters/listingsapi/models/Listing";
import { NewProduct } from "../adapters/listingsapi/models/NewProduct";
import { Product, ProfileListing } from "../adapters/listingsapi/models/ProfileListing";
import { MediaAssetArray } from "../../../../../kit/MediaEditor/MediaAsset/MediaAssetArray";

export type ListingsStoreState = {
  listings: Loadable<ListingList>;

  // We need to store the page, to prevent repeated data on double fetch
  // when React app is in StrictMode (development mode).
  page: number;

  activeListing: Loadable<ProfileListing>;
  activeProduct: Loadable<Product>;

  // It's a necessary part for working pagination, as on initial fetch of the
  // first page we need to know if the pagination observer should be enabled,
  // by providing the information of the next page existence (if 1st page has items).
  hasMorePages: boolean;
  
  newProduct: Loadable<NewProduct>;
};

export const initialState: ListingsStoreState = {
  listings: loading({ products: [] }),
  page: 0,
  activeListing: loading({
    id: "",
    name: "",
    description: "",
    active: false,
    products: [],
  }),
  activeProduct: loading({
    price: 0,
    stock: 0,
    media: [],
    sale: 0,
    primary: false,
  }),
  hasMorePages: false,
  newProduct: loading({
    id: "",
    options: {
      available: [],
      choose: [],
    },
    price: 0,
    stock: 0,
    assets: new MediaAssetArray(),
  }),
};
