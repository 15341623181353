import React, { Fragment } from "react";
import { SignInMethod } from "./models/SignIn";
import { SeoHeader } from "lisa/common/components";
import { useAuthentication } from "./store/thunks";
import { AppState } from "../../store/store";
import { useAppSelector } from "../../store/hooks";
import { useAuthenticationRedirect } from "./hooks/useAuthenticationRedirect";
import { useMediaQuery } from "react-responsive";
import SignInHeader from "./components/SignInHeader";
import { Container, PopupContainer } from "./styles";

import WidgetInitial from "./components/WidgetInitial";
import WidgetSmsSignIn from "./components/WidgetSmsSignIn";

const SignInPage = () => {
  useAuthenticationRedirect();
  const state = useAppSelector((state: AppState) => state.authentication);
  const {
    navigate,
    verifyPhoneWithSms,
    verifyChallenge
  } = useAuthentication();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const submitPhoneWithSms = (phone: string) => {
    const prefixedPhone = "380" + phone;
    verifyPhoneWithSms(prefixedPhone);
  };

  const submitCodeWithSms = (challenge: string) => {
    const challengeToken = state.data.challengeToken;
    const phone = state.data.sms.phone;
    verifyChallenge(phone, challenge, challengeToken);
  };

  const RenderForm = () => {
    if (state.data.method === SignInMethod.Initial) {
      return (
        <WidgetInitial
          loading={state.status === "loading"}
          error={state.status === "error" ? state.error : null}
          navigate={navigate}
          phone={state.data.sms.phone}
          onSubmitPhone={submitPhoneWithSms}
        />
      );
    }

    if (state.data.method === SignInMethod.Sms) {
      return (
        <WidgetSmsSignIn
          loading={state.status === "loading"}
          error={state.status === "error" ? state.error : null}
          navigate={navigate}
          phone={state.data.sms.phone}
          challenge={state.data.challengeToken}
          onSubmitCode={submitCodeWithSms}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      <SeoHeader
        title="Вхід в аккаунт"
        description="Вхід в аккаунт Brandlink"
        url="/signin"
        imageUrl="/logo.png"
      />
      <main style={{position: "relative", height: "var(--full-vh)", overflow: "hidden"}}>
        <SignInHeader />
        <Container>
          <PopupContainer $desktop={isDesktop}>
            {RenderForm()}
          </PopupContainer>
        </Container>
      </main>
    </Fragment>
  );
};

export default SignInPage;

// --------------------------------------------------------
