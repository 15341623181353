import { Profile } from "../adapters/accountsapi/models/Profile";
import { Loadable, loading } from "../../../../common/store/Loadable";
import { AccountPaymentPreferences } from "../adapters/accountsapi/models/SetupPaymentPreferences";
import { BillingPlan, BillingPreferences } from "../../billing/adapters/billingapi/models/BillingPreferences";
import { PaymentMode } from "../../../../customer/features/feed/adapters/models/PaymentMode";

export type ProfileStoreState = {
  profile: Loadable<Profile>;
  paymentPreferences: Loadable<AccountPaymentPreferences>;
  billing: Loadable<BillingPreferences>;
};

export const initialState: ProfileStoreState = {
  profile: loading({
    account: {
      media: undefined,
      name: "",
      firstName: undefined,
      middleName: undefined,
      lastName: undefined,
      instagram: "",
      notifications: false,
      private: false,
      displayName: "",
      playgroundMode: false,
      paymentMode: PaymentMode.DuringOrder,
      paymentPartialAmount: undefined,
      paymentPartialIsRefundable: undefined,
      orderCancelPolicy: undefined,
    },
    delivery: {
      services: {},
    },
  }),
  paymentPreferences: loading({
    type: 1,
    hasSecret: false,
  }),
  billing: loading({
    email: "",
    plan: BillingPlan.None,
    nextBillingDate: ""
  }),
};
