import styled, { css } from "styled-components";

export const Card = styled.div<{
  $active: boolean;
}>`
  position: relative;
  cursor: pointer;
  //transition: background-color 0.3s ease-in-out;
  padding: 20px 18px;
  gap: 5px;
  border-radius: 12px;
  border: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    //transition: border-color 0.3s ease-in-out, border-width 0.3s ease-in-out;
    border: ${({ $active }) =>
      $active ? "1px solid var(--text-106)" : "0.8px solid #C6C0C0"};
  }

  &:hover {
    background-color: var(--bg-input-hover);
  }

  &:focus {
    background-color: var(--bg-input-hover);
  }
`;


export const Description = styled.p`
  font-size: 0.863rem;
  font-weight: 300;
  color: #636363;
  line-height: 120%;
`;

export const CardError = styled.div`
  margin-top: 4px;
`;
