import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, Loadable, LoadableStatus, loading } from "lisa/common/store/Loadable";
import { initialPaymentPreferences, PaymentPreferences } from "../models/PaymentPreferences";

type PaymentPreferencesState =  Loadable<PaymentPreferences>

const initialState: PaymentPreferencesState = loading(initialPaymentPreferences);

const paymentPreferencesSlice = createSlice({
  name: "paymentPrefs",
  initialState,
  reducers: {
    updatePaymentPreferences(state) {
      state.status = LoadableStatus.Loading;
    },
    updatePaymentPreferencesSuccess(state, action: PayloadAction<PaymentPreferences>) {
      state.status = LoadableStatus.Success;
      state.error = undefined;
      state.data = {...state.data, ...action.payload};
    },
    updatePaymentPreferencesError(state, action: PayloadAction<UIErrorCompact>) {
      state.status = LoadableStatus.Error;
      state.error = action.payload;
      state.data = {...state.data};
    }
  }
});

export const {
  updatePaymentPreferences,
  updatePaymentPreferencesSuccess,
  updatePaymentPreferencesError,
} = paymentPreferencesSlice.actions;

export default paymentPreferencesSlice.reducer;