import { useAppDispatch } from "../../../../common/store/hooks";
import { AppDispatch } from "../../../../common/store/store";
import { UIError } from "../../../../common/store/UIError";
import {
  cancelBilling,
  cancelBillingError,
  cancelBillingSuccess,
  fetchBillingDetails,
  fetchBillingDetailsError,
  fetchBillingDetailsSuccess,
  fetchBillingPreferences,
  fetchBillingPreferencesError,
  fetchBillingPreferencesSuccess,
  startSubscription,
  startSubscriptionError,
  startSubscriptionSuccess,
  startTrial,
  startTrialError,
  startTrialSuccess,
  updateBillingPreferences,
} from "./slice";
import { billingApi } from "../adapters/billingapi";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppErrorCode } from "lisa/common/adapters/apicoreapi/models";
import { SnackBarInfo } from "lisa/common/components/SnackBar/SnackBarInfo";
import { showSnackBarWithTimeoutThunk } from "lisa/common/features/ui/store/thunks";
import { BillingPlan, BillingPreferences } from "../adapters/billingapi/models/BillingPreferences";
import { BillingEmail } from "../adapters/billingapi/models/BillingEmail";

export const useBilling = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    fetchBillingDetails: (): Promise<BillingPreferences> =>
      dispatch(fetchBillingDetailsThunk()),
    startTrial: (): Promise<void> => dispatch(startTrialThunk()),
    startSubscription: (plan: BillingPlan): Promise<void> =>
      dispatch(startSubscriptionThunk(plan)),
    cancelBilling: (): Promise<void> => dispatch(cancelBillingThunk()),
    checkFreeTrial: (): Promise<void> =>
      dispatch(checkFreeTrialThunk(navigate)),
    fetchBillingPreferences: () => dispatch(fetchBillingPreferencesThunk()),
  };
};

const fetchBillingDetailsThunk =
  () =>
  async (dispatch: AppDispatch): Promise<BillingPreferences> => {
    dispatch(fetchBillingDetails());

    try {
      const details = await billingApi.fetchBillingPreferences();
      dispatch(fetchBillingDetailsSuccess(details));
      return details;
    } catch (error: unknown) {
      const uiError = UIError.fromError(
        error,
        (appError): string | undefined => {
          // TODO: Implement custom error handling
          // if (appError.code === AppErrorCode.BadRequest) {
          //   return "Невірний формат телефону";
          // }
          return undefined;
        }
      );
      dispatch(fetchBillingDetailsError(uiError));
      return Promise.reject(uiError);
    }
  };

const startTrialThunk =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(startTrial());

    try {
      await billingApi.startTrial();
      dispatch(startTrialSuccess());
    } catch (error: unknown) {
      const uiError = UIError.fromError(
        error,
        (appError): string | undefined => {
          return undefined;
        }
      );
      dispatch(startTrialError(uiError));
      return Promise.reject(uiError);
    }
  };

const startSubscriptionThunk =
  (plan: BillingPlan) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(startSubscription());

    try {
      await billingApi.startSubscription(plan);
      dispatch(startSubscriptionSuccess(plan));
    } catch (error: unknown) {
      const uiError = UIError.fromError(
        error,
        (appError): string | undefined => {
          return undefined;
        }
      );
      dispatch(startSubscriptionError(uiError));
      return Promise.reject(uiError);
    }
  };

const cancelBillingThunk =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(cancelBilling());

    try {
      await billingApi.cancelSubscription();
      dispatch(cancelBillingSuccess());
    } catch (error: unknown) {
      const uiError = UIError.fromError(
        error,
        (appError): string | undefined => {
          return undefined;
        }
      );
      dispatch(cancelBillingError(uiError));
      return Promise.reject(uiError);
    }
  };

const checkFreeTrialThunk =
  (navigate: NavigateFunction) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchBillingDetails());

      const details = await billingApi.fetchBillingPreferences();

      dispatch(fetchBillingDetailsSuccess(details));

      navigate("/lisa/merchant/billing/trial", { replace: true });
    } catch (error: unknown) {
      const uiError = UIError.fromError(
        error,
        (appError): string | undefined => {
          if (appError.code === AppErrorCode.NotFound) {
            navigate("/lisa/merchant/billing/trial", { replace: true });
            return undefined;
          }
        }
      );
      dispatch(fetchBillingDetailsError(uiError));
      return Promise.reject(uiError);
    }
  };

  export const updateBillingPreferencesThunk =
  (email: BillingEmail) => async (dispatch: AppDispatch) => {
    try {
      await billingApi.updateBillingPreferences(email);

      dispatch(updateBillingPreferences(email));

      // const snackBarInfo: SnackBarInfo = {
      //   title: "Email успішно оновлено",
      //   description: "Ваші зміни збережено",
      //   actionName: "Закрити",
      //   isError: true,
      // };
      // dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
    } catch (error: unknown) {
      const uiError = UIError.fromError(error);
      const snackBarInfo: SnackBarInfo = {
        title: "Не вдалося оновити email",
        description: "Помилка оновлення email",
        actionName: "Закрити",
        isError: true,
      };
      dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
      return Promise.reject(uiError);
    }
  };

export const fetchBillingPreferencesThunk = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchBillingPreferences());

    const prefs = await billingApi.fetchBillingPreferences();
    dispatch(fetchBillingPreferencesSuccess(prefs));
  } catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      return undefined;
    });
    dispatch(fetchBillingPreferencesError(uiError.getCompact()));
  }
};

