import { AppDispatch } from "lisa/common/store/store";
import { UIError } from "lisa/common/store/UIError";
import AccountsApiInstance from "../adapters/accountsapi/AccountsApi";
import { SnackBarInfo } from "lisa/common/components/SnackBar/SnackBarInfo";
import { showSnackBarWithTimeoutThunk } from "lisa/common/features/ui/store/thunks";
import { OrderPolicyPreferences } from "../models/OrderPolicyPreferences";
import {
  updateOrderPolicyPreferences,
  updateOrderPolicyPreferencesError,
  updateOrderPolicyPreferencesSuccess
} from "./deliveryPreferencesSlice";
import { updateDeliveryPreferencesForProfile } from "./profileSlice";
import { mapDeliveryPreferencesToApi } from "../adapters/accountsapi/models/ApiDeliveryPreferences";

export const updateOrderPolicyPreferencesThunk = (prefs: OrderPolicyPreferences) => async (dispatch: AppDispatch) => {
  const apiPrefs = mapDeliveryPreferencesToApi(prefs);
  if(Object.keys(apiPrefs.services).length == 0) {
    return; // Prevent from sending an empty {} services, when customer edits a portion of preferences related to profile, not delivery (payment mode).
  }

  dispatch(updateOrderPolicyPreferences());

  try {
    await AccountsApiInstance.updateDeliveryPreferences(apiPrefs);
    dispatch(updateDeliveryPreferencesForProfile(apiPrefs));
    dispatch(updateOrderPolicyPreferencesSuccess(prefs));

    const snackBarInfo: SnackBarInfo = {
      title: "Налаштування збережено",
      description: "Ваші зміни збережено",
      actionName: "Закрити",
      isError: false
    }
    dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
  }  catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      // TODO: Implement custom error handling
      // if (appError.code === AppErrorCode.BadRequest) {
      //   return "Невірний формат телефону";
      // }
      return undefined;
    });

    const snackBarInfo: SnackBarInfo = {
      title: "Помилка",
      description: uiError.getMessage(),
      actionName: "Закрити",
      isError: true
    }
    dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
    dispatch(updateOrderPolicyPreferencesError(uiError.getCompact()));
  }
}