import { styled } from "styled-components";
import { TextTagColors } from "./index";

export const Tag = styled.div<{ $color: TextTagColors }>`
    padding: 4px 8px;
    width: fit-content;
    border-radius: 6px;

    line-height: 1rem;
    font-size: .65rem;
    font-weight: 400;
    color: #FFF;
    
    background-color: ${({ $color }) =>
        $color === "accent"
        ? "#3e3b3b"
        : $color === "yellow"
        ? "#AE810F"
        : $color === "red"
        ? "#821A1B"
        : "#3e3b3b"}; // Default color
`;