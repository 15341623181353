import { BillingEmail } from "./BillingEmail";

export type BillingPreferences = {
  email: BillingEmail;
  plan: BillingPlan;
  nextBillingDate: string;
};

export enum BillingPlan {
  Trial = "trial",
  Base = "base",
  None = "none" 
}
