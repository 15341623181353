import { AppDispatch } from "lisa/common/store/store";
import { updateShopPreferencesForProfile } from "./profileSlice";
import {
  ProfilePreferencesForm,
  updateShopPreferences,
  updateShopPreferencesError,
  updateShopPreferencesSuccess
} from "./shopPreferencesSlice";
import { UIError } from "lisa/common/store/UIError";
import AccountsApiInstance from "../adapters/accountsapi/AccountsApi";
import { SnackBarInfo } from "lisa/common/components/SnackBar/SnackBarInfo";
import { showSnackBarWithTimeoutThunk } from "lisa/common/features/ui/store/thunks";
import { updateBillingPreferencesThunk } from "../../billing/store/thunks";
import { AccountProfile } from "../adapters/accountsapi/models/AccountProfile";
import { extractImageMediaId } from "lisa/common/features/media/models/MediaMetadata";

export const updateShopPreferencesThunk =
  (actualPrefs: ProfilePreferencesForm, changedPrefs: ProfilePreferencesForm, enabledBillingFeatureFlag: boolean) =>
    async (dispatch: AppDispatch) => {
      dispatch(updateShopPreferences());

      try {
        // Extract only changed fields for PATCH request
        const updatedFields = getUpdatedFields(actualPrefs, changedPrefs);

        const account: AccountProfile = {
          media: extractMediaId(updatedFields.media),
          coverMedia: updatedFields.coverMedia,
          displayName: updatedFields.displayName,
          instagram: updatedFields.instagramUsername,
          tiktok: updatedFields.tiktokUsername,
          private: updatedFields.private,
          paymentMode: updatedFields.paymentMode,
          paymentPartialAmount: updatedFields.paymentPartialAmount,
          playgroundMode: updatedFields.playgroundMode,
          orderCancelPolicy: updatedFields.orderCancelPolicy,
          paymentPartialIsRefundable: updatedFields.paymentPartialIsRefundable
        };
        if (Object.keys(account).length == 0) {
          console.warn('No changes in account preferences. Skip API call.')
          return
        }

        await AccountsApiInstance.updateAccount(account);

        if (enabledBillingFeatureFlag) {
          dispatch(updateBillingPreferencesThunk(changedPrefs.billingEmail));
        }

        dispatch(updateShopPreferencesSuccess(changedPrefs));
        dispatch(updateShopPreferencesForProfile(changedPrefs));

        const snackBarInfo: SnackBarInfo = {
          title: "Налаштування збережено",
          description: "Ваші зміни збережено",
          actionName: "Закрити",
          isError: false
        };
        dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
      } catch (error: unknown) {
        const uiError = UIError.fromError(
          error,
          (appError): string | undefined => {
            // TODO: Implement custom error handling
            // if (appError.code === AppErrorCode.BadRequest) {
            //   return "Невірний формат телефону";
            // }
            return undefined;
          }
        );

        const snackBarInfo: SnackBarInfo = {
          title: "Помилка",
          description: uiError.getMessage(),
          actionName: "Закрити",
          isError: true
        };
        dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
        dispatch(updateShopPreferencesError(uiError));
      }
    };

const extractMediaId = (mediaIdOrUrl: string | undefined): string | undefined => {
  if (!mediaIdOrUrl) {
    return undefined;
  }

  try {
    return extractImageMediaId(mediaIdOrUrl);
  } catch (e) {
    return mediaIdOrUrl;
  }
};

function getUpdatedFields<T extends Object>(actual: T, changed: T): Partial<T> {
  const updatedFields: Partial<T> = {};
  for (const key of Object.keys(changed) as (keyof T)[]) {
    if (actual[key] !== changed[key] && changed[key] !== undefined) {
      updatedFields[key] = changed[key];
    }
  }
  return updatedFields;
}