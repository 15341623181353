import { css } from "styled-components";


export const hiddenScrollBar = css`
    /* Hides scrollbar for Chrome, Safari, Edge */
    &::-webkit-scrollbar {
        width: 0; /* Effectively removes scrollbar */
        display: none;
    }
    /* Hides scrollbar for Firefox */
    scrollbar-width: none;
`