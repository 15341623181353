import styled, { css } from "styled-components";

export const mixinFlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const mixinFlexRowCenterVertical = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Gap = styled.div<{ $gap: 1 | 2 | 4 | 6 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 32 | 36 | 48 | 60 | 64 | 80}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: ${props => props.$gap}px;
`;

type FlexProps = {
  $direction?: 'row' | 'column';
  $justify?: 'center' | 'space-between' | 'right' | 'left';
  $align?: 'center' | 'flex-end' | 'flex-start' | 'end';
  $wrap?: 'nowrap' | 'wrap';
  $gap?: 0 | 1 | 2 | 6 | 8 | 10 | 12 | 14 | 16 | 22 | 24 | 26 | 30 | 32 | 40 | 42 | 48 | 80;
  $fullHeight?: boolean;
  $fullWidth?: boolean;
  $position?: 'relative';
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
  align-items: ${({ $align }) => $align || 'stretch'};
  flex-wrap: ${({ $wrap }) => $wrap || 'nowrap'};
  gap: ${({ $gap }) => `${$gap || 0}px`};
    
  ${({ $fullHeight }) => $fullHeight && css`
    height: 100%;
  `};

  ${({ $fullWidth }) => $fullWidth && css`
    width: 100%;
  `};

  ${({ $position }) => $position && css`
      position: ${$position};
  `};
`;

export default Flex;