import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { Fragment, lazy, Suspense } from "react";
import { GARouteChangeListener } from "../monitoring/googleAnalytics";
import { ThemeProvider } from "styled-components";

import GlobalStyle from "lisa/common/styles/GlobalStyle";
import { HelmetProvider } from "react-helmet-async";
import useTheme from "lisa/common/styles/useTheme";
import { provideTheme } from "lisa/common/styles/theme";
import SignInPage from "lisa/common/features/authentication/SignInPage";
import { SessionGuard } from "lisa/common/features/session/SessionGuard";
import CreateAccountPage from "lisa/common/features/authentication/CreateAccountPage";
import { RootLayout } from "lisa/common/components/RootLayout";
import { useViewportHeight } from "../lisa/common/hooks/useViewportHeight";

const LandingPage = lazy(() => import("pages/Landing/LandingPage"));
const OrderSuccessPage = lazy(
  () => import("feed/pages/OrderSuccessPage/OrderSuccessPage")
);
const OrderErrorPage = lazy(
  () => import("feed/pages/OrderErrorPage/OrderErrorPage")
);

const MerchantRouter = lazy(() => import("./merchantRouter"));

// Lisa routers
const LisaMerchantRouter = lazy(() => import("lisa/merchant"));
const CustomerRouter = lazy(() => import("lisa/customer/features"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/lisa" element={<SessionGuard />}>
        <Route path="account/new" element={<CreateAccountPage />} />
      </Route>

      <Route element={<RootLayout />}>
        <Route
          path="/lisa/merchant/*"
          element={
            <Suspense fallback={<Fragment />}>
              <LisaMerchantRouter />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<Fragment />}>
              <LandingPage />
            </Suspense>
          }
        />

        <Route path="/lisa/signin" element={<SignInPage />} />

        <Route
          path="/*"
          element={
            <Suspense fallback={<Fragment />}>
              <CustomerRouter />
            </Suspense>
          }
        />

        {/* Merchant routes */}
        <Route
          path="/merchant/*"
          element={
            <Suspense fallback={<Fragment />}>
              <MerchantRouter />
            </Suspense>
          }
        />

        <Route
          path="/order/success"
          element={
            <Suspense fallback={<Fragment />}>
              <OrderSuccessPage />
            </Suspense>
          }
        />

        <Route
          path="/order/error"
          element={
            <Suspense fallback={<Fragment />}>
              <OrderErrorPage />
            </Suspense>
          }
        />
      </Route>
    </Route>
  )
);

const App = () => {
  const [theme] = useTheme();
  useViewportHeight();

  return (
    <HelmetProvider>
      <ThemeProvider theme={provideTheme(theme)}>
        <GlobalStyle />
        <GARouteChangeListener />
        <RouterProvider router={router} />
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
