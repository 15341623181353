export const ListingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8.95063 8.25H15.0494C15.7142 8.24997 16.2871 8.24993 16.7458 8.31161C17.2375 8.37771 17.7087 8.52677 18.091 8.90901C18.4732 9.29126 18.6223 9.76252 18.6884 10.2542C18.7501 10.7129 18.75 11.2857 18.75 11.9506V16.0494C18.75 16.7142 18.7501 17.2871 18.6884 17.7458C18.6223 18.2375 18.4732 18.7087 18.091 19.091C17.7087 19.4732 17.2375 19.6223 16.7458 19.6884C16.2871 19.7501 15.7143 19.75 15.0495 19.75H8.95064C8.28581 19.75 7.71288 19.7501 7.25416 19.6884C6.76252 19.6223 6.29126 19.4732 5.90901 19.091C5.52677 18.7087 5.37771 18.2375 5.31161 17.7458C5.24993 17.2871 5.24996 16.7142 5.25 16.0494V11.9506C5.24996 11.2858 5.24993 10.7129 5.31161 10.2542C5.37771 9.76252 5.52677 9.29126 5.90901 8.90901C6.29126 8.52677 6.76252 8.37771 7.25416 8.31161C7.7129 8.24993 8.28577 8.24997 8.95063 8.25ZM7.45403 9.79823C7.12873 9.84197 7.02677 9.91258 6.96967 9.96967C6.91258 10.0268 6.84197 10.1287 6.79823 10.454C6.7516 10.8009 6.75 11.2717 6.75 12V16C6.75 16.7283 6.7516 17.1991 6.79823 17.546C6.84197 17.8713 6.91258 17.9732 6.96967 18.0303C7.02677 18.0874 7.12873 18.158 7.45403 18.2018C7.80091 18.2484 8.27169 18.25 9 18.25H15C15.7283 18.25 16.1991 18.2484 16.546 18.2018C16.8713 18.158 16.9732 18.0874 17.0303 18.0303C17.0874 17.9732 17.158 17.8713 17.2018 17.546C17.2484 17.1991 17.25 16.7283 17.25 16V12C17.25 11.2717 17.2484 10.8009 17.2018 10.454C17.158 10.1287 17.0874 10.0268 17.0303 9.96967C16.9732 9.91258 16.8713 9.84197 16.546 9.79823C16.1991 9.7516 15.7283 9.75 15 9.75H9C8.27169 9.75 7.80091 9.7516 7.45403 9.79823Z"
          fill="#262626" />
    <path
      d="M7 5.25C6.58579 5.25 6.25 5.58579 6.25 6C6.25 6.41421 6.58579 6.75 7 6.75H12C12.4142 6.75 12.75 6.41421 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25H7Z"
      fill="#262626" />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M10.9436 1.25H13.0564C14.8942 1.24998 16.3498 1.24997 17.489 1.40314C18.6614 1.56076 19.6104 1.89288 20.3588 2.64124C21.1071 3.38961 21.4392 4.33856 21.5969 5.51098C21.75 6.65018 21.75 8.1058 21.75 9.94354V14.0564C21.75 15.8942 21.75 17.3498 21.5969 18.489C21.4392 19.6614 21.1071 20.6104 20.3588 21.3588C19.6104 22.1071 18.6614 22.4392 17.489 22.5969C16.3498 22.75 14.8942 22.75 13.0565 22.75H10.9436C9.10585 22.75 7.65018 22.75 6.51098 22.5969C5.33856 22.4392 4.38961 22.1071 3.64124 21.3588C2.89288 20.6104 2.56076 19.6614 2.40314 18.489C2.24997 17.3498 2.24998 15.8942 2.25 14.0564V9.94358C2.24998 8.10583 2.24997 6.65019 2.40314 5.51098C2.56076 4.33856 2.89288 3.38961 3.64124 2.64124C4.38961 1.89288 5.33856 1.56076 6.51098 1.40314C7.65019 1.24997 9.10583 1.24998 10.9436 1.25ZM6.71085 2.88976C5.70476 3.02502 5.12511 3.27869 4.7019 3.7019C4.27869 4.12511 4.02502 4.70476 3.88976 5.71085C3.75159 6.73851 3.75 8.09318 3.75 10V14C3.75 15.9068 3.75159 17.2615 3.88976 18.2892C4.02502 19.2952 4.27869 19.8749 4.7019 20.2981C5.12511 20.7213 5.70476 20.975 6.71085 21.1102C7.73851 21.2484 9.09318 21.25 11 21.25H13C14.9068 21.25 16.2615 21.2484 17.2892 21.1102C18.2952 20.975 18.8749 20.7213 19.2981 20.2981C19.7213 19.8749 19.975 19.2952 20.1102 18.2892C20.2484 17.2615 20.25 15.9068 20.25 14V10C20.25 8.09318 20.2484 6.73851 20.1102 5.71085C19.975 4.70476 19.7213 4.12511 19.2981 3.7019C18.8749 3.27869 18.2952 3.02502 17.2892 2.88976C16.2615 2.75159 14.9068 2.75 13 2.75H11C9.09318 2.75 7.73851 2.75159 6.71085 2.88976Z"
          fill="#262626" />
  </svg>

);