import { apicoreApi, RestApi } from "lisa/common/adapters/apicoreapi";
import { Access, ChallengeResponseSms, ChallengeSession, PhoneRequest, RefreshTokenStatus } from "./models";

export interface IAuthenticationRestApi {
  sendChallengeSms(phone: PhoneRequest): Promise<ChallengeSession>;
  signInSms(data: ChallengeResponseSms): Promise<Access>;
  refresh(): Promise<RefreshTokenStatus>;
  createAccount(username: string, instagram: string): Promise<void>;
}

export class AuthenticationRestApiImpl implements IAuthenticationRestApi {
  private readonly api: RestApi;

  constructor(api: RestApi) {
    this.api = api;
  }

  public async sendChallengeSms(phone: PhoneRequest): Promise<ChallengeSession> {
    return await this.api.post<ChallengeSession>(`/id/challenge/sms`, phone)
  }

  public async signInSms(data: ChallengeResponseSms): Promise<Access> {
    return await this.api.post<Access>(`/id/signin/sms`, data, {
      withCredentials: true,
    })
  }
  
  public async refresh(): Promise<RefreshTokenStatus> {
    return await this.api.post<RefreshTokenStatus>('/id/refresh', null, {
      withCredentials: true,
    })
  }

  public async createAccount(username: string, instagram: string): Promise<void> {
    return await this.api.post<void>('/accounts/brand', { username, instagram }, {
      withCredentials: true,
    })
  }
}

// Api instance
const AuthenticationApiInstance: IAuthenticationRestApi = new AuthenticationRestApiImpl(apicoreApi);

export default AuthenticationApiInstance;