export enum OrderState {
  All,
  New,
  Canceled,
  InProgress,
  Delivering,
  Finished,
}

export const OrderStateOptions = new Map([
  [OrderState.All, "Усі"],
  [OrderState.New, "Нове"],
  [OrderState.Canceled, "Відхилено"],
  [OrderState.InProgress, "В обробці"],
  [OrderState.Delivering, "В доставці"],
  [OrderState.Finished, "Виконано"],
]);

export const validTransitions: Record<OrderState, OrderState[]> = {
  [OrderState.All]: [],
  [OrderState.New]: [OrderState.InProgress, OrderState.Canceled],
  [OrderState.InProgress]: [OrderState.Delivering],
  [OrderState.Delivering]: [OrderState.Finished],
  [OrderState.Finished]: [],
  [OrderState.Canceled]: [],
};