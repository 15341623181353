export const ErrorIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="6.99984" cy="7.0013" r="5.83333" stroke="#C70101" strokeWidth="1" />
      <path d="M7 4.08203V7.58203" stroke="#C70101" strokeWidth="1" strokeLinecap="round" />
      <circle cx="6.99984" cy="9.33333" r="0.583333" fill="#C70101" />
    </g>
    <defs>
      <clipPath id="clip0_175_7736">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>

)