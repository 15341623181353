export const MAX_LISTING_PHOTO_MEDIA_SIZE = 50; // in MB
export const MAX_LISTING_VIDEO_MEDIA_SIZE = 100; // in MB
export const MAX_MEDIA_QUANTITY = 20;

export const MAX_VIDEO_DURATION = 30; // in seconds
export const allowedVideoTypes = [
  "video/mp4",
  // "video/hevc",
  // "video/quicktime",
  // "application/octet-stream",
];

export const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
];
export const MAX_PHOTO_SIZE_IN_BYTES = MAX_LISTING_PHOTO_MEDIA_SIZE * 1024 * 1024;
export const MAX_VIDEO_SIZE_IN_BYTES = MAX_LISTING_VIDEO_MEDIA_SIZE * 1024 * 1024;

export enum MediaErrorType {
  WrongPhotosQuantity = 1,
  WrongPhotoFormat,
  WrongVideoFormat,
  WrongVideosQuantity,
  WrongVideoDuration,
  WrongPhotoSize,
  NoPhotos
}

export const isMediaError = async (file: File): Promise<MediaErrorType | null> => {
    const isAllowedImage = allowedImageTypes.includes(file.type);
    const isAllowedVideo = allowedVideoTypes.includes(file.type);

    if (!isAllowedImage && !isAllowedVideo) {
      return MediaErrorType.WrongPhotoFormat
    }

    if (file.type.startsWith("image/") && !isAllowedImage) {
      return MediaErrorType.WrongPhotoFormat
    }

    if (file.type.startsWith("video/") && !isAllowedVideo) {
        return MediaErrorType.WrongVideoFormat
    }
  
    if (isAllowedImage && file.size > MAX_PHOTO_SIZE_IN_BYTES) {
      return MediaErrorType.WrongPhotoSize
    }
  
    if (isAllowedVideo) {
      const videoDuration = await getVideoDuration(file);
      if (videoDuration > MAX_VIDEO_DURATION) {
        return MediaErrorType.WrongVideoDuration
      }
    }

  return null
}

export const getVideoDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.preload = "metadata";

        const url = URL.createObjectURL(file); // Create a unique URL
        video.onloadedmetadata = () => {
            URL.revokeObjectURL(url); // Revoke the URL once metadata is loaded
            resolve(video.duration);
        };

        video.onerror = () => {
            reject(new Error("Failed to load video metadata"));
        };

        video.src = url; // Use the unique URL
    });
};
