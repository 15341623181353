import { DeliveryType, OrderPolicyPreferences } from "../../../models/OrderPolicyPreferences";
import { Profile } from "./Profile";
import { PaymentMode } from "../../../../../../customer/features/feed/adapters/models/PaymentMode";

export type ApiDeliveryPreferences = {
  services: ApiDeliveryServices
};

// --------------------------------------------
export type ApiDeliveryServices = {
  [key in ApiDeliveryServiceType]?: ApiDeliveryServiceDetails;
}

export enum ApiDeliveryServiceType {
  DeliveryTypePickup = 1,
  DeliveryTypeCourier = 2,
  DeliveryTypeNewPost = 3,
}

export type ApiDeliveryServiceDetails = {
  [key: string]: string;
};

export const mapDeliveryPreferencesToApi = (prefs: OrderPolicyPreferences): ApiDeliveryPreferences => {
  let deliveryPreferences: ApiDeliveryPreferences = {
    services: {},
  };

  for (let i = 0; i < prefs.deliveryTypes.length; i++) {
    const deliveryType = prefs.deliveryTypes[i];

    if (deliveryType == DeliveryType.NovaPoshta) {
      deliveryPreferences.services[ApiDeliveryServiceType.DeliveryTypeNewPost] = {};
    }

    if (deliveryType == DeliveryType.Courier) {
      deliveryPreferences.services[ApiDeliveryServiceType.DeliveryTypeCourier] = {
        location: prefs.city,
      };
    }

    if (deliveryType == DeliveryType.Pickup) {
      deliveryPreferences.services[ApiDeliveryServiceType.DeliveryTypePickup] = {
        location: prefs.city,
        fullAddress: prefs.address,
      };
    }
  }

  return deliveryPreferences;
}

export const mapApiToDeliveryPreferences = (apiPrefs: Profile): OrderPolicyPreferences => {
  let deliveryPreferences: OrderPolicyPreferences = {
    deliveryTypes: [],
    city: '',
    address: '',
    paymentMode: PaymentMode.DuringOrder,
    paymentPartialAmount: undefined,
    paymentPartialIsRefundable: undefined,
    orderCancelPolicy: undefined,
  };

  const services = apiPrefs.delivery.services;

  // Iterate through the delivery services and map them back to DeliveryPreferences
  for (const serviceTypeKey in services) {
    const serviceType = Number(serviceTypeKey); // Convert the string key to a number
    const serviceDetails = services[serviceType as ApiDeliveryServiceType];

    switch (Number(serviceType)) {
      case ApiDeliveryServiceType.DeliveryTypeNewPost:
        deliveryPreferences.deliveryTypes.push(DeliveryType.NovaPoshta);
        break;

      case ApiDeliveryServiceType.DeliveryTypeCourier:
        deliveryPreferences.deliveryTypes.push(DeliveryType.Courier);
        if (serviceDetails) {
          deliveryPreferences.city = serviceDetails.location || deliveryPreferences.city;
        }
        break;

      case ApiDeliveryServiceType.DeliveryTypePickup:
        deliveryPreferences.deliveryTypes.push(DeliveryType.Pickup);
        if (serviceDetails) {
          deliveryPreferences.city = serviceDetails.location || deliveryPreferences.city;
          deliveryPreferences.address = serviceDetails.fullAddress || deliveryPreferences.address;
        }
        break;

      default:
        // Handle unknown service types if necessary
        break;
    }
  }

  deliveryPreferences.paymentMode = apiPrefs.account.paymentMode
  deliveryPreferences.paymentPartialAmount = apiPrefs.account.paymentPartialAmount
  deliveryPreferences.paymentPartialIsRefundable = apiPrefs.account.paymentPartialIsRefundable
  deliveryPreferences.orderCancelPolicy = apiPrefs.account.orderCancelPolicy

  return deliveryPreferences;
}