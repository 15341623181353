import { Loadable, loading } from "../../../../common/store/Loadable";
import { Order } from "../adapters/ordersapi/models/Order";
import { PaymentOrderType, PaymentStatus } from "../adapters/ordersapi/models/OrderPayment";
import { OrderState } from "../adapters/ordersapi/models/OrderState";
import { OrderThumbnailList } from "../adapters/ordersapi/models/OrderThumbnail";
import { PaymentMode } from "../../../../customer/features/feed/adapters/models/PaymentMode";

export type OrdersStoreState = {
  thumbnails: Loadable<OrderThumbnailList>;
  activeOrder: Loadable<{
    updatedState: OrderState;
    value: Order;
    cancelReason?: string;
  }>;
};

const initOrder: Order = {
  orderNumber: "",
  state: 1,
  customer: {
    phone: "",
    firstName: "",
    lastName: "",
  },
  payment: {
    type: PaymentOrderType.PaymentTypeNone,
    status: PaymentStatus.Pending,
  },
  refund: undefined,
  delivery: {
    type: 1,
    city: "",
    fullAddress: "",
    fullApartment: "",
    desiredDate: "",
    desiredTime: "",
  },
  orderItems: [
    {
      listingId: "",
      productId: "",
      media: "",
      name: "",
      price: -1,
      amount: -1,
      sale: -1
    },
  ],
  paymentMode: PaymentMode.DuringOrder,
  paymentPartialAmount: undefined,
  createdAt: new Date(),
};

export const initialState: OrdersStoreState = {
  thumbnails: loading({
    state: OrderState.All,
    page: 0,
    orders: [],
  }),
  activeOrder: loading({
    updatedState: initOrder.state,
    value: initOrder,
    cancelReason: undefined,
  }),
};
