import { DefaultTheme } from "styled-components";

export type Theme = {
  accent: string;
  accentDisabled: string;
  accentHover: string;
  accentActive: string;
  error: string;

  // Light and dark theme color definitions
  text101: string; // High contrast text color
  text100: string; // Base text color
  text102: string; // Base text color
  text104: string; // Feed text
  text105: string; // Header menu text
  text106: string; // Preferences secondary text
  text107: string; // Preferences buttons
  text108: string; // Preferences title
  text109: string; // Preferences description
  text110: string; // Save button
  text111: string; // Shop preferences images text
  text112: string; // Callout text
  bgLight101: string; // High contrast background color
  bgLight100: string; // Base background color
  bgLight102: string; // Grey background color
  bgIcon100: string; // Base icon color
  bgIcon101: string;
  bgIcon102: string;
  bgLazyImage: string;
  bgDivider100: string;
  bgDivider101: string;
  bgThemeSwitch: string;
  bgPopup: string;
  bgPopupButton: string;
  bgSwitchOn: string;
  bgSwitchOff: string;
  bgSwitchOnHover: string;
  bgSwitchOffHover: string;
  bgBorder: string;
  bgBorderSignIn: string;
  bgChoiceHover: string;
  bgChoice: string;
  bgInput: string;
  bgInputHover: string;
  bgInputSignIn: string;
  bgChoiceDescription: string;
  bgBackButtonHover: string;
  bgCloseButtonHover: string;
  bgAccentButton: string;
  bgBillingCard: string;
  bgBackButton: string;
  bgDividerContent: string;
  bgAddButton: string;
  bgCallout: string;
  bgHintError: string;

  text: {
    primary: string;
    secondary: string;
    primaryInverted: string;
  };
  sizes: {
    mobilePadding: string;
    text: {
      small: string;
      medium: string;
      regular: string;
      base: string;
    };
  };
};

export const colors = {
  // accent: '#24ABFF',
  // accentDisabled: '#82CEFD',
  // accentHover: '#40B6FF',
  // accentActive: '#10A0F9',
  accent: "#262626",
  accentDisabled: "#d8d8d8",
  accentHover: "#343434",
  accentActive: "#0a0a0a",
  error: "#C70101",
  text: {
    primary: "#282828",
    secondary: "#474747",
    primaryInverted: "#ffffff",
  },
};

export const lightTheme: Theme = {
  ...colors,
  accentHover: "#343434",
  accentActive: "#0a0a0a",
  text101: "#000000", // High contrast text color
  text100: "#262626", // Base text color
  text102: "#FFFFFF",
  text104: "#292929",
  text105: "#322B2F",
  text106: "#32302B",
  text107: "#8B8983",
  text108: "#262626",
  text109: "#32302B",
  text110: "#B6B4AE",
  text111: "#5F5E5B",
  text112: "#5C5B58",
  bgLight101: "#ffffff", // High contrast background color
  bgLight100: "#FFF", // Base background color
  bgLight102: "#E7E7E7", // Grey background color
  bgIcon100: "#000", // Base icon color
  bgIcon101: "#1F1F1F",
  bgIcon102: "#DADADA",
  bgLazyImage: "#f4f4f4",
  bgDivider100: "#E2E0DC",
  bgDivider101: "#E2E0DC",
  bgThemeSwitch: "#EEEBE6",
  bgPopup: "#FFF",
  bgPopupButton: "#FFFFFF",
  bgSwitchOn: "#545454",
  bgSwitchOff: "#cecece",
  bgSwitchOnHover: "#282828",
  bgSwitchOffHover: "#b4b4b4",
  bgBorder: "#C6C0C0",
  bgBorderSignIn: "#E1E1E1",
  bgChoiceHover: "#FFF",
  bgChoice: "#F8F7F6",
  bgInput: "#FFF",
  bgInputSignIn: "#FFF",
  bgInputHover: "#f8f8f8",
  bgChoiceDescription: "#4C4B49",
  bgBackButtonHover: "#EEECE7",
  bgCloseButtonHover: "#E7E3DD",
  bgAccentButton: "#2D2B26",
  bgBillingCard: "#FFFFFF",
  bgBackButton: "#F0EFED",
  bgDividerContent: "#C4C1C1",
  bgAddButton: "#4C4A45CC",
  bgCallout: "#ECE9E4",
  bgHintError: "#AA0505",
  sizes: {
    mobilePadding: "30px",
    text: {
      small: "0.8125rem", // 13px
      medium: "0.875rem", // 14px
      regular: "0.95rem", // 15.2px
      base: "1rem", // 16px
    },
  },
};

export const darkTheme: Theme = {
  ...colors,
  accentHover: "#cecece",
  accentActive: "#cecece",
  text101: "#ffffff", // High contrast text color
  text100: "#ffffff", // Base text color
  text102: "#000000",
  text104: "#DADADA",
  text105: "#BFBFBF",
  text106: "#BFBFBF",
  text107: "#BFBFBF",
  text108: "#BFBFBF",
  text109: "#AAA9A5",
  text110: "#474645",
  text111: "#BFBFBF",
  text112: "#8B8B8B",
  bgLight101: "#000000", // High contrast background color
  bgLight100: "#0D0D0D", // Base background color
  bgLight102: "#242424", // Grey background color
  bgIcon100: "#FFFFFF", // Base icon color
  bgIcon101: "#D0CBBF",
  bgIcon102: "#322B2F",
  bgLazyImage: "#181818",
  bgDivider100: "#3A3A3A",
  bgDivider101: "#3A3A3A",
  bgThemeSwitch: "#252524",
  bgPopup: "#191919",
  bgPopupButton: "#252524",
  bgSwitchOn: "#cecece",
  bgSwitchOff: "#514E49",
  bgSwitchOnHover: "#b4b4b4",
  bgSwitchOffHover: "#282828",
  bgBorder: "#3A3A3A",
  bgBorderSignIn: "#2A2A2A",
  bgChoiceHover: "#191919",
  bgChoice: "#191919",
  bgInput: "#191919",
  bgInputSignIn: "#272727",
  bgInputHover: "#1D1D1D",
  bgChoiceDescription: "#AAA9A5",
  bgBackButtonHover: "#2B2B2B",
  bgCloseButtonHover: "#323130",
  bgAccentButton: "#F3F3F3",
  bgBillingCard: "#121212CC",
  bgBackButton: "#1E1D1D",
  bgDividerContent: "#585858",
  bgAddButton: "#E7E3DBCC",
  bgCallout: "#262626",
  bgHintError: "#F74040",
  sizes: {
    mobilePadding: "30px",
    text: {
      small: "0.8125rem", // 13px
      medium: "0.875rem", // 14px
      regular: "0.95rem", // 15.2px
      base: "1rem", // 16px
    },
  },
};

export enum ThemeType {
  Light = "light",
  Dark = "dark",
  Auto = "auto",
}

export const provideTheme = (themeType: ThemeType): DefaultTheme => {
  return themeType === ThemeType.Light ? lightTheme : darkTheme;
};