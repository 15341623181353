import { keyframes, styled } from "styled-components";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { FixedBackdrop } from "lisa/common/styles/components/Backdrop";

type Props = {
  size: Size

  fullPage: boolean
  withBackdrop?: boolean;
  dark?: boolean;

  // Is required to show a spinner in delayed mode
  delay?: number;
}

type Size = "base" | "small";

export const UniversalSpinner = (props: Props) => {
  const { size, fullPage, delay, withBackdrop, dark } = props;

  // Delayed rendering
  const showLoader = useDelayedLoader(delay);
  const withDelay = delay !== undefined;

  const isDark = (): boolean => {
    if(withBackdrop) {
      return false;
    }

    if(dark !== undefined) {
      return dark;
    }

    return true;
  }

  if (withDelay && !showLoader) {
    return null;
  }

  const RenderWithContainer = (children: ReactNode): ReactElement => {
    // Render in a "backdrop" mode
    if (withBackdrop) {
      return (
        <FixedBackdrop>
          <FullPageContainer>
            {children}
          </FullPageContainer>
        </FixedBackdrop>
      );
    }

    // Render in a "full page" mode
    if (fullPage) {
      return (
        <FullPageContainer>
          {children}
        </FullPageContainer>
      );
    }

    // Render in a "general" mode
    return (
      <Fragment>{children}</Fragment>
    );
  };

  return RenderWithContainer(
    <Icon $size={size}>
      <SpinnerIcon dark={isDark()} />
    </Icon>
  );
};

const SpinnerIcon = ({ dark }: { dark: boolean }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.0833333"
          d="M27.7604 4.15827L23.9604 10.7401C23.629 11.314 23.8256 12.0479 24.3996 12.3793C24.9735 12.7107 25.7074 12.514 26.0388 11.9401L29.8388 5.35827C30.1702 4.78432 29.9735 4.05041 29.3996 3.71904C28.8256 3.38767 28.0917 3.58432 27.7604 4.15827Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.166667"
          d="M34.6425 10.1605L28.0607 13.9605C27.4868 14.2919 27.2901 15.0258 27.6215 15.5998C27.9529 16.1737 28.6868 16.3704 29.2607 16.039L35.8425 12.239C36.4165 11.9076 36.6131 11.1737 36.2817 10.5998C35.9504 10.0258 35.2165 9.82918 34.6425 10.1605Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.25"
          d="M37.5998 18.8008H29.9998C29.3371 18.8008 28.7998 19.338 28.7998 20.0008C28.7998 20.6635 29.3371 21.2008 29.9998 21.2008H37.5998C38.2625 21.2008 38.7998 20.6635 38.7998 20.0008C38.7998 19.338 38.2625 18.8008 37.5998 18.8008Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.333333"
          d="M35.8417 27.7613L29.2599 23.9613C28.686 23.63 27.9521 23.8266 27.6207 24.4006C27.2893 24.9745 27.486 25.7084 28.0599 26.0398L34.6417 29.8398C35.2157 30.1712 35.9496 29.9745 36.281 29.4006C36.6123 28.8266 36.4157 28.0927 35.8417 27.7613Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.416667"
          d="M29.8395 34.6425L26.0395 28.0607C25.7081 27.4868 24.9742 27.2901 24.4002 27.6215C23.8263 27.9529 23.6296 28.6868 23.961 29.2607L27.761 35.8425C28.0924 36.4165 28.8263 36.6131 29.4002 36.2817C29.9742 35.9504 30.1708 35.2165 29.8395 34.6425Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.5"
          d="M21.2002 37.6008V30.0008C21.2002 29.338 20.6629 28.8008 20.0002 28.8008C19.3375 28.8008 18.8002 29.338 18.8002 30.0008V37.6008C18.8002 38.2635 19.3375 38.8008 20.0002 38.8008C20.6629 38.8008 21.2002 38.2635 21.2002 37.6008Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.583333"
          d="M12.2396 35.8417L16.0396 29.2599C16.371 28.686 16.1744 27.9521 15.6004 27.6207C15.0265 27.2893 14.2926 27.486 13.9612 28.0599L10.1612 34.6417C9.82982 35.2157 10.0265 35.9496 10.6004 36.281C11.1744 36.6123 11.9083 36.4157 12.2396 35.8417Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.666667"
          d="M5.35749 29.8395L11.9393 26.0395C12.5132 25.7081 12.7099 24.9742 12.3785 24.4002C12.0471 23.8263 11.3132 23.6296 10.7393 23.961L4.15749 27.761C3.58354 28.0924 3.38689 28.8263 3.71826 29.4002C4.04963 29.9742 4.78354 30.1708 5.35749 29.8395Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.75"
          d="M2.4002 21.1992H10.0002C10.6629 21.1992 11.2002 20.662 11.2002 19.9992C11.2002 19.3365 10.6629 18.7992 10.0002 18.7992H2.4002C1.73745 18.7992 1.2002 19.3365 1.2002 19.9992C1.2002 20.662 1.73745 21.1992 2.4002 21.1992Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.833333"
          d="M4.15827 12.2387L10.7401 16.0387C11.314 16.37 12.0479 16.1734 12.3793 15.5994C12.7107 15.0255 12.514 14.2916 11.9401 13.9602L5.35827 10.1602C4.78432 9.82884 4.05041 10.0255 3.71904 10.5994C3.38767 11.1734 3.58432 11.9073 4.15827 12.2387Z"
          fill={dark ? "black" : "white"} />
    <path opacity="0.916667"
          d="M10.1605 5.35749L13.9605 11.9393C14.2919 12.5132 15.0258 12.7099 15.5998 12.3785C16.1737 12.0471 16.3704 11.3132 16.039 10.7393L12.239 4.15749C11.9076 3.58354 11.1737 3.38689 10.5998 3.71826C10.0258 4.04963 9.82918 4.78354 10.1605 5.35749Z"
          fill={dark ? "black" : "white"} />
  </svg>
);

const useDelayedLoader = (delay: number | undefined): boolean => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  // Initialize timer with a type that allows it to be null
  let timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (delay == undefined) {
      return;
    }
    timer = setTimeout(() => {
      setShowLoader(true);
    }, delay);

    // Clean up the timeout when the component unmounts or if loading changes
    return () => {
      if (timer !== null) {
        clearTimeout(timer);  // Clear the timer to avoid memory leaks
      }
    };
  }, [delay]);

  if (delay === undefined) {
    return showLoader;
  }

  return showLoader;
};

const FullPageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
`;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Icon = styled.div<{ $size: Size }>`
    width: ${({ $size }) => ($size === "small" ? "16px" : "24px")};
    height: ${({ $size }) => ($size === "small" ? "16px" : "24px")};

    animation: ${spin} 1s linear infinite;

    svg {
        width: 100%;
        height: 100%;
    }
`;