import React, { ReactNode, useState } from "react";
import { useField } from "formik";
import {
  Container,
  Prefix,
  StyledInput,
} from "./styles";
import { Error } from "./Error";
import { CSSProp } from "styled-components";
import Flex from "../Flex";

type Props = {
  tid: string;
  name: string;

  type?: string;
  placeholder?: string;
  prefixGap?: number;
  autoFocus?: boolean;
  autoComplete?: boolean;
  customStyles?: CSSProp;

  // Controls whether to render the form block
  renderCondition?: boolean;

  PrefixElement?: ReactNode;
  Hint?: ReactNode;
};

export const Input: React.FC<Props> = ({
  tid,
  name,
  type,
  Hint,
  placeholder,
  PrefixElement,
  prefixGap,
  autoFocus,
  autoComplete,
  customStyles,
  renderCondition,
}) => {
  const [field, meta] = useField(name);
  const [focused, setFocused] = useState(false);

  const hasError = meta.touched && meta.error !== undefined;

  const focus = () => setFocused(true);

  const blur = (e: React.FocusEvent) => {
    field.onBlur(e);
    setFocused(false);
  };

  const inputId = `${name}-input`;

  const isValuePresent = field.value !== null && field.value !== undefined && field.value !== "";

  if (renderCondition !== undefined && !renderCondition) {
    return null;
  }

  return (
    <div>
      <Container
        $active={focused}
        $prefixGap={prefixGap}
        $error={hasError}
        $customStyles={customStyles}
      >
        {PrefixElement && (
          <Prefix>
            <Flex>{PrefixElement}</Flex>
          </Prefix>
        )}
        <StyledInput
          data-test-id={tid}
          id={inputId}
          lang="uk"

          type={type}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete ? "on" : "off"}
          value={field.value}
          name={field.name}

          onFocus={focus}
          onChange={field.onChange}
          onBlur={blur}
        />
      </Container>
      {Hint}
      {hasError && meta.error ? <Error tid={`${name}-input-error`} text={meta.error} /> : null}
    </div>
  );
};
