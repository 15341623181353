import styled, { css } from "styled-components";
import { mixinFlexCenter } from "lisa/common/styles/components/Flex";

export const BaseContainer = styled.div`
  ${mixinFlexCenter};
  align-items: unset;

  flex-direction: column;
  height: 100%;

  width: 100%;
  margin: 0 auto;
  padding: 24px;

  position: relative;

  @media (min-width: 768px) {
    width: 430px;
    padding: 30px 40px;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div<{ $desktop: boolean }>`
  background-color: var(--bg-popup);
  color: var(--text-100);
  position: relative;
  overflow: hidden;
  width: ${({ $desktop }) => ($desktop ? "fit-content" : "100%")};
  height: ${({ $desktop }) => ($desktop ? "fit-content" : "100%")};
  border-radius: ${({ $desktop }) => ($desktop ? "12px" : "0")};
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;

  @media (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
`;

export const backButtonStyles = css`
  padding: 12px;
  border-radius: 50%;
  background-color: var(--bg-back-button);
  width: 44px;
  height: 44px;

  &:hover {
    background-color: var(--bg-back-button-hover);
  }

  &:focus {
    background-color: var(--bg-back-button-hover);
  }
`;

export const TextContainer = styled.div<{ $width?: "full" }>`
  text-align: center;
  margin: 0 auto;
  width: ${({ $width }) => ($width === "full" ? "100%" : "248px")};
`;
