import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { Profile } from "../adapters/accountsapi/models/Profile";
import { initialState } from "./ProfileStoreState";
import { error, loading, success } from "../../../../common/store/Loadable";
import { ProfilePreferencesForm } from "./shopPreferencesSlice";
import { ApiDeliveryPreferences } from "../adapters/accountsapi/models/ApiDeliveryPreferences";
import { AccountPaymentPreferences } from "../adapters/accountsapi/models/SetupPaymentPreferences";

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfile(state) {
      state.profile = loading(state.profile.data);
    },
    fetchProfileSuccess(state, action: PayloadAction<Profile>) {
      state.profile = success({ ...action.payload });
    },
    fetchProfileError(state, action: PayloadAction<UIErrorCompact>) {
      state.profile = error({ ...state.profile.data }, action.payload);
    },
    fetchPaymentPreferences(state) {
      state.paymentPreferences = loading(state.paymentPreferences.data);
    },
    fetchPaymentPreferencesSuccess(
      state,
      action: PayloadAction<AccountPaymentPreferences>
    ) {
      state.paymentPreferences = success({ ...action.payload });
    },
    fetchPaymentPreferencesError(state, action: PayloadAction<UIErrorCompact>) {
      state.paymentPreferences = error(
        { ...state.paymentPreferences.data },
        action.payload
      );
    },
    updateShopPreferencesForProfile(
      state,
      action: PayloadAction<ProfilePreferencesForm>
    ) {
      state.profile = {
        ...state.profile,
        data: {
          ...state.profile.data,
          account: {
            ...state.profile.data.account,
            ...action.payload,
          },
        },
      };
    },
    updateDeliveryPreferencesForProfile(
      state,
      action: PayloadAction<ApiDeliveryPreferences>
    ) {
      state.profile = {
        ...state.profile,
        data: {
          ...state.profile.data,
          delivery: action.payload,
        },
      };
    },
    updatePaymentPreferencesForProfile(
      state,
      action: PayloadAction<AccountPaymentPreferences>
    ) {
      state.paymentPreferences.data = action.payload;
    },
  },
});

export const {
  fetchProfile,
  fetchProfileSuccess,
  fetchProfileError,

  fetchPaymentPreferences,
  fetchPaymentPreferencesSuccess,
  fetchPaymentPreferencesError,

  updateShopPreferencesForProfile,
  updateDeliveryPreferencesForProfile,
  updatePaymentPreferencesForProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
