import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { SessionStorageAdapter } from "../../session/adapters/SessionStorageAdapter";

export const useAuthenticationRedirect = () => {
  const WELCOME_PAGE = "/lisa/account/new";
  const HOME_PAGE = "/lisa/merchant";

  const navigate = useNavigate();

  useEffect(() => {
    const hasAccessToken = SessionStorageAdapter.hasAccessToken();
    const hasAccount = SessionStorageAdapter.hasAccountComplete();

    if (hasAccessToken && !hasAccount) {
      navigate(WELCOME_PAGE, { replace: true });
      return;
    }

    if (hasAccessToken) {
      navigate(HOME_PAGE, { replace: true });
      return;
    }
  }, []);
}