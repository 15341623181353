import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";

import { logger } from "lib/logger";
import { wrapIntoAppError } from "./restAppError";
import { RefreshTokenStatus } from "../../features/authentication/adapters/models";
import { SessionStorageAdapter } from "../../features/session/adapters/SessionStorageAdapter";
import { AppError, AppErrorCode } from "./models";

type ApiConfig = {
  url: string;
};

class RestApi {
  private readonly api: AxiosInstance;

  constructor(configuration: ApiConfig, withCredentials: boolean = true) {
    logger.log(`api endpoint: ${configuration.url}`);

    this.api = axios.create({
      baseURL: configuration.url,
      withCredentials: withCredentials,
      headers: {
        "Content-Type": "application/json"
      },
    });

    // Request Interceptor
    this.api.interceptors.request.use(
      (config) => {
        config.headers.Authorization = SessionStorageAdapter.getBearerToken();
        return config;
      }
    );

    // Axios response interceptor
    this.api.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        throw wrapIntoAppError(error);
      }
    );
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.wrapApiCall<T>(() => this.api.get(url, config));
  }

  public delete(url: string, config?: AxiosRequestConfig): Promise<void> {
    return this.wrapApiCall(() => this.api.delete(url, config));
  }

  public post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.wrapApiCall<T>(() => this.api.post(url, data, config));
  }

  public put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.wrapApiCall<T>(() => this.api.put(url, data, config));
  }

  public patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.wrapApiCall<T>(() => this.api.patch(url, data, config));
  }

  private async wrapApiCall<T>(call: () => AxiosPromise<T>): Promise<T> {
    try {
      const response = await call();
      return response.data;
    } catch (error) {
      const appErr = error as AppError;

      switch (appErr.status) {
        case 401:
          SessionStorageAdapter.removeAll();
          break;

        case 403:
          try {
            const response = await this.post<RefreshTokenStatus>("/id/refresh");
            const accessToken = response.accessToken;

            if (accessToken.length === 0) {
              SessionStorageAdapter.removeAll();
              throw new AppError(AppErrorCode.UnknownClientError, "Failed to refresh token");
            }

            SessionStorageAdapter.setAccessToken(accessToken);
            const retryResponse = await call();
            return retryResponse.data;

          } catch (refreshErr) {
            SessionStorageAdapter.removeAll();
            throw wrapIntoAppError(refreshErr);
          }
      }
      throw appErr;
    }
  }
}

export {
  //
  RestApi
};
