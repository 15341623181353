import * as Yup from 'yup';
import { AppError, AppErrorCode } from "../../adapters/apicoreapi/models";

export const phoneValidationSchema = Yup.object({
  phone: Yup.string()
    .matches(/^\d{9}$/, 'Будь ласка, введіть 9 цифр')
    .required('Введіть ваш номер телефону'),
});

export const codeValidationSchema = Yup.object({
  code: Yup.string()
    .matches(/^\d{5}$/, 'Будь ласка, введіть 5 цифр')
    .required('Код є обов\'язковим'),
});

export const accountValidationSchema = Yup.object({
  username: Yup.string()
    .min(3, 'Ім\'я профілю повинно містити принаймні 3 символи')
    .max(20, 'Ім\'я профілю повинно містити не більше 20 символів')
    .matches(/^[a-z0-9._-]+$/, 'Ім\'я профілю може містити лише малі літери, цифри, ".", "_", та "-"')
    .required('Будь ласка, введіть унікальне ім\'я профілю'),
  instagram: Yup.string()
    .required('Будь ласка, введіть Instagram вашого бренду'),
});

export const createAccountApiValidation = (appError: AppError): string | undefined => {
  if (appError.code === AppErrorCode.BadRequest) {
    // TODO: Improve validation of createAccountApiValidation to parse validation details and give the direct reason what field failed validation
    return "Невірний  формат username або instagram. Спробуйте змінити та спробувати ще раз";
  } else if (appError.code === AppErrorCode.Conflict) {
    return "Брендлінк з таким username вже існує. Спробуйте інший username";
  }
}