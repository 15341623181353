import { Loadable, loading } from "lisa/common/store/Loadable";
import { CheckoutProduct } from "../models/CheckoutProduct";

type CheckoutState = {
  checkoutProducts: Loadable<CheckoutProduct[]>;
};

export const initialState: CheckoutState = {
  checkoutProducts: loading([]),
};
