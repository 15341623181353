import { SignInMethod, SignInStep } from "../models/SignIn";
import {
  BackButtonContainer,
  backButtonStyles,
  BaseContainer,
  TextContainer,
} from "../styles";
import { Gap } from "lisa/common/styles/components/Flex";
import { AccentButton, BackButton } from "lisa/common/styles/components/Button";
import { Formik, FormikProps } from "formik";
import { Input } from "lisa/common/styles/components/Form";
import { codeValidationSchema } from "../validation";
import { Error } from "../../../styles/components/Form/Error";
import { ReactNode } from "react";
import { UIErrorCompact } from "../../../store/UIError";
import { AppErrorCode } from "../../../adapters/apicoreapi/models";
import { Heading } from "../../../styles/components/Typography/Heading";
import { Text } from "lisa/common/styles/components/Typography/Text";

type Props = {
  loading: boolean;
  error: UIErrorCompact | null;

  phone: string;
  challenge: string;

  navigate: (method: SignInMethod, step: SignInStep) => void;
  onSubmitCode: (code: string) => void;
};

const WidgetSmsSignIn = (props: Props) => {
  const { loading, error, phone, challenge, navigate, onSubmitCode } = props;

  const back = () => {
    navigate(SignInMethod.Initial, SignInStep.Phone);
  };

  const renderError = (tid: string): ReactNode => {
    return error !== null ? <Error tid={tid} text={error.message} /> : null;
  };

  const initialValues = {
    code: challenge,
  };

  return (
    <>
      <BaseContainer>
        <BackButtonContainer>
          <BackButton
            tid="back-btn"
            kind="big"
            onClick={back}
            customStyle={backButtonStyles}
          />
        </BackButtonContainer>
        <Gap $gap={60}>
          <TextContainer>
            <Gap $gap={12}>
              <Heading as="h1" $size="medium">
                Увійти
              </Heading>
              <Text
                $size="small"
                $color="signInDescription"
              >{`Введіть код з смс, відправлений на номер ${phone}`}</Text>
            </Gap>
          </TextContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={codeValidationSchema}
            onSubmit={(values) => onSubmitCode(String(values.code))}
            validateOnBlur
          >
            {({
              handleSubmit,
              isValid,
              dirty,
            }: FormikProps<typeof initialValues>) => (
              <form onSubmit={handleSubmit} data-test-id={"verify-code-form"}>
                <Gap $gap={16}>
                  <div>
                    <Input
                      tid="code"
                      name="code"
                      placeholder="Код з смс"
                      type="number"
                      autoFocus
                    />
                    {renderError("verify-form-error")}
                  </div>
                  <AccentButton
                    tid="submit-btn"
                    title="Увійти"
                    disabled={
                      !isValid ||
                      !dirty ||
                      error?.code === AppErrorCode.TooManyRequests
                    }
                    loading={loading}
                    type="submit"
                    fullWidth
                  />
                </Gap>
              </form>
            )}
          </Formik>
        </Gap>
      </BaseContainer>
    </>
  );
};

export default WidgetSmsSignIn;
