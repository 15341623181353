import { LocalStorageBase, LocalStorageModel } from "lisa/common/adapters/localstorage";
import { ProductToCheckout } from "../models/ProductToCheckout";

export type CheckoutLocalStorageModel = LocalStorageModel & {
  data: Array<ProductToCheckout>;
};

const newCheckoutLocalStorageModel = (modelVersion: number): CheckoutLocalStorageModel => ({
  v: modelVersion,
  data: []
});

export interface ICheckoutLocalStorage {
  getProductsAmount(): number;

  getAll(): Array<ProductToCheckout>;

  addProduct(product: ProductToCheckout): void;

  setProductAmountByID(id: string, updatedAmount: number): number;

  removeProductByID(id: string): number;

  removeAll(): void;
}

export class CheckoutLocalStorage
  extends LocalStorageBase<CheckoutLocalStorageModel> implements ICheckoutLocalStorage {

  constructor() {
    const KEY = "order";
    const MODEL_VERSION = 1;

    super(KEY, MODEL_VERSION);
  }

  isModelValid(data: unknown): data is CheckoutLocalStorageModel {
    if (
      typeof data === "object" &&
      data !== null &&
      "v" in data &&
      typeof (data as CheckoutLocalStorageModel).v === "number" &&
      "data" in data &&
      Array.isArray((data as CheckoutLocalStorageModel).data)
    ) {
      return (data as CheckoutLocalStorageModel).data.every((item) =>
        typeof item.brandName === "string" &&
        typeof item.listingId === "string" &&
        typeof item.productId === "string" &&
        typeof item.amount === "number"
      );
    }
    return false;
  }

  public getProductsAmount(): number {
    const orders = this.get();
    return orders ? orders.data.reduce((sum, item) => sum + item.amount, 0) : 0;
  }

  public addProduct(product: ProductToCheckout): number {
    const orders = this.get() ?? newCheckoutLocalStorageModel(this.modelVersion);

    // For lisa 2.1.0 we will push each product separately as we've updated the cart UI
    // const foundProduct = orders.data.find(
    //   (item) => item.productId === product.productId
    // );
    //
    // if (foundProduct) {
    //   foundProduct.amount += product.amount;
    // } else {
      orders.data.push(product);
    // }

    this.set(orders);
    return this.getProductsAmount();
  }

  public removeProductByID(productID: string): number {
    const orders = this.get();
    if (!orders) return 0;

    // For lisa 2.1.0 we will push each product separately as we've updated the cart UI
    // orders.data = orders.data.filter(
    //   (product) => product.productId !== productID
    // );

    const index = orders.data.findIndex(product => product.productId === productID);
    if (index !== -1) {
      orders.data.splice(index, 1);
    }

    this.set(orders);
    return this.getProductsAmount();
  }

  public removeAll(): void {
    this.set({ v: this.modelVersion, data: [] });
  }

  public getAll(): Array<ProductToCheckout> {
    const products = this.get();
    if (products === undefined) {
      return [];
    }

    return products.data;
  }

  public setProductAmountByID(id: string, updatedAmount: number): number {
    const orders = this.get();

    if (orders === undefined) {
      return 0;
    }

    const foundProduct = orders.data.find((item) => id === item.productId);
    if (foundProduct) {
      foundProduct.amount = updatedAmount;
    }

    this.set(orders);
    return this.getProductsAmount();
  }
}