import { LocalStorageModel } from "./LocalStorageModel";

export abstract class LocalStorageBase<T extends LocalStorageModel> {
  private readonly key: string;
  public readonly modelVersion: number;

  abstract isModelValid(data: unknown): boolean;

  constructor(
    key: string,
    modelVersion: number
  ) {
    this.key = key;
    this.modelVersion = modelVersion;
    this.ensureModelCorrectness();
  }

  protected get(): T | undefined {
    const raw = localStorage.getItem(this.key);
    if (raw) {
      return JSON.parse(raw);
    }

    return undefined;
  }

  protected set(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  private ensureModelCorrectness(): void {
    const data = this.get();

    if (!data || data.v !== this.modelVersion || !this.isModelValid(data)) {
      this.resetModel();
    }
  }

  private resetModel(): void {
    localStorage.removeItem(this.key);
  }
}