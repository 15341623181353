import AuthenticationApiInstance from "../adapters/rest";
import {
  navigate,
  verifyChallenge, verifyChallengeError, verifyChallengeSuccess,
  verifyPhoneWithSms,
  verifyPhoneWithSmsError,
  verifyPhoneWithSmsSuccess,
} from "./slice";
import { UIError } from "../../../store/UIError";
import { AppErrorCode } from "../../../adapters/apicoreapi/models";
import { AppDispatch } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import { Access, ChallengeResponseSms } from "../adapters/models";
import { SignInMethod, SignInStep } from "../models/SignIn";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { SessionStorageAdapter } from "../../session/adapters/SessionStorageAdapter";

export const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const routerNavigate = useNavigate();

  return {
    navigate: (method: SignInMethod, step: SignInStep) =>
      dispatch(navigate({method, step})),
    verifyPhoneWithSms: (phone: string) =>
      dispatch(verifyPhoneWithSmsThunk(phone)),
    verifyChallenge: (phone: string, challenge: string, challengeToken: string) =>
      dispatch(verifyChallengeThunk(phone, challenge, challengeToken, routerNavigate)),
  }
}

const verifyPhoneWithSmsThunk = (phone: string) => async (dispatch: AppDispatch) => {
  dispatch(verifyPhoneWithSms());

  const request = {
    phoneNumber: phone
  }

  try {
    const challengeSession = await AuthenticationApiInstance.sendChallengeSms(request);
    dispatch(verifyPhoneWithSmsSuccess({ phone: phone, session: challengeSession.challengeToken }));
  } catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      if (appError.code === AppErrorCode.BadRequest) {
        return "Невірний формат телефону";
      }
    });
    dispatch(verifyPhoneWithSmsError(uiError.getCompact()));
  }
};

export const verifyChallengeThunk = (phone: string, challenge: string, challengeToken: string, routerNavigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(verifyChallenge());

  const request: ChallengeResponseSms = {
    phoneNumber: phone,
    challenge: challenge,
    challengeToken: challengeToken,
  }

  try {
    const access = await AuthenticationApiInstance.signInSms(request);
    dispatch(verifyChallengeSuccess(access));

    // Store access token and identity completion status in local storage
    storeSessionDetails(access);

    // Redirect authenticated user to the next page (preferences or create account)
    if (access.complete) {
      routerNavigate("/lisa/merchant");
    } else {
      routerNavigate("/lisa/account/new");
    }
  } catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      if (appError.code === AppErrorCode.BadRequest) {
        return "Невірний код, ви маєте 3 спроби ввести правильний код з смс";
      } else if (appError.code === AppErrorCode.TooManyRequests) {
        return "Ви ввели неправильний код 3 рази. Поверніться назад, щоб надіслати код повторно";
      }
    });
    dispatch(verifyChallengeError(uiError.getCompact()));
  }
}

const storeSessionDetails = (access: Access): void => {
  SessionStorageAdapter.setSessionDetails(access.accessToken, access.complete);
}
