import styled, { css, CSSProp } from "styled-components";
import { mixinFlexRowCenterVertical } from "../Flex";
import { mixinContainer, mixinText } from "./stylesCommon";
import { hiddenScrollBar } from "../../common";

export const Container = styled.div<{
  $active: boolean;
  $error?: boolean;
  $prefixGap?: number;
  $customStyles?: CSSProp;
}>`
  ${mixinContainer};

  /* Allow custom overrides */
  ${(props) => props.$customStyles && props.$customStyles}
`;

export const Prefix = styled.span`
  ${mixinText};
`;

export const StyledInput = styled.input`
  ${mixinText};

  border-radius: 12px;
  width: 100%;
  background-color: transparent !important;

  padding: 20px 20px 20px 0;
`;

export const ErrorContainer = styled.div`
  ${mixinFlexRowCenterVertical};
  align-items: initial;

  gap: 5px;

  font-size: ${(props) => props.theme.sizes.text.small};
  font-weight: 300;
  color: ${(props) => props.theme.error};

  padding: 5px;
`;

export const ErrorIconContainer = styled.div`
  min-width: 14px;
  margin-top: 1px;
`;

export const StyledTextArea = styled.textarea<{ $height: string }>`
  ${mixinText};
    
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent !important;

  padding: 20px 20px 20px 0;

  height: ${(props) => props.$height};
`;

export const LengthLimit = styled.p<{
  $focused: boolean;
  $error: boolean;
}>`
  margin-top: 6px;
  color: var(--text-107);
  font-weight: 300;
  font-size: 0.75rem;

  ${({ $focused }) =>
    $focused &&
    css`
      color: #1a1a1a;
    `}

  ${({ $error }) =>
    $error &&
    css`
      color: #aa0505;
      //font-weight: 400;
    `}
`;

export const InputDropdown = styled.ul`
  ${hiddenScrollBar};
  position: absolute;
  z-index: 999;
  margin-top: 8px;
  width: 100%;
  max-height: calc(58px * 5);
  overflow-y: auto;

  border-radius: 12px;
  border: 0.9px solid #c6c0c0;

  box-shadow: 0 0 2px 0 rgba(0, 0, 32, 0.02),
    0 2px 6px 0 rgba(0, 0, 32, 0.03), 0 7px 30px 0 rgba(0, 0, 32, 0.07);
  background-color: var(--bg-input);
`;

export const InputDropdownItem = styled.li`
  ${mixinText};

  padding: 16px 24px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--bg-input-hover);
    color: var(--color-text-primary);
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
`;

export const FormHint = styled.div`
    cursor: default;
    
    font-size: 0.813rem;
    font-weight: 300;
    color: #1F1F1F;
    
    margin-top: 12px;
`