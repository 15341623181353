import { ErrorContainer, ErrorIconContainer } from "./styles";
import { ErrorIcon } from "lisa/common/icons/ErrorIcon";

export const Error = ({text, tid}: {text: string, tid?: string}) => {
  return (
    <ErrorContainer data-test-id={tid}>
      <ErrorIconContainer>
        <ErrorIcon />
      </ErrorIconContainer>
      {text}
    </ErrorContainer>
  )
}