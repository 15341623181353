import { PaymentMode } from "../../../../customer/features/feed/adapters/models/PaymentMode";
import { TextTag } from "../../../../common/components";
import React from "react";

export enum DeliveryType {
  NovaPoshta = "nova_poshta",
  Courier = "courier",
  Pickup = "pickup",
}

export type OrderPolicyPreferences = {
  // Delivery type matches the options in Choice component
  deliveryTypes: string[];
  city: string;
  address: string;
  paymentMode: PaymentMode;
  paymentPartialAmount: number | undefined;
  paymentPartialIsRefundable: boolean | undefined;
  orderCancelPolicy: string | undefined;
}

export const initialOrderPolicyPreferences: OrderPolicyPreferences = {
  deliveryTypes: [],
  city: "",
  address: "",
  paymentMode: PaymentMode.DuringOrder,
  paymentPartialAmount: undefined,
  paymentPartialIsRefundable: undefined,
  orderCancelPolicy: undefined
}

export const DELIVERY_OPTIONS = [
  {
    id: DeliveryType.NovaPoshta,
    label: "Нова Пошта",
    description: "Доставка сервісом Нова Пошта",
  },
  {
    id: DeliveryType.Courier,
    label: "Кур’єр / Таксі у місті",
    description: "Локальна доставка кур’єром чи таксі",
  },
  {
    id: DeliveryType.Pickup,
    label: "Самовивіз у місті",
    description: "Самовивіз покупцем за адресою магазину",
  },
];

export const PAYMENT_MODE_OPTIONS = [
  {
    id: PaymentMode.DuringOrder,
    label: "Під час замовлення",
    description: "Покупець може здійснити оплату виключно онлайн під час оформлення замовлення через інтегровану платіжну систему.",
  },
  {
    id: PaymentMode.AfterShipping,
    label: "З можливістю післяплати",
    description: "Поєднує функції “Під час замовлення”, дозволяючи покупцю оплатити товар при отриманні, якщо він не хоче здійснювати оплату одразу під час замовлення.",
    BottomElement: <TextTag color="accent" text="Рекомендовано"/>
  },
  {
    id: PaymentMode.Partial,
    label: "З фіксованою передплатою",
    description: "Поєднує функції “Під час замовлення” та післяплати, але вимагає фіксованої передплати при оформленні замовлення.",
  },
]

export const ALLOW_PARTIAL_PAYMENT_RETURN_OPTIONS = [
  {
    id: "allow",
    label: "Дозволити повернення передплати",
    description: "Дозволяючи повернення, покупець зможе побачити повідомлення, що можливо отримати кошти за передплату назад у разі якщо товар не буде отриманий під час доставки.",
    BottomElement: <TextTag color="accent" text="Рекомендовано"/>
  },
]