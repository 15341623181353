export const ActivatedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="var(--text-100)" strokeWidth="1" />
      <path
        d="M8.5 12.5L10.5 14.5L15.5 9.5"
        stroke="var(--text-100)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

