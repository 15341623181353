import { useEffect, useRef } from "react";
import { useAppSelector } from "lisa/common/store/hooks";
import { AppState } from "lisa/common/store/store";
import { useProfile } from "lisa/merchant/features/profile/store/profileThunks";
import { useBilling } from "lisa/merchant/features/billing/store/thunks";
import { Outlet } from "react-router-dom";
import { SessionStorageAdapter } from "lisa/common/features/session/adapters/SessionStorageAdapter";

export const RootLayout = () => {
  const profileState = useAppSelector((state: AppState) => state.profile);
  const { fetchProfile, fetchPaymentPreferences } = useProfile();
  const { fetchBillingDetails } = useBilling();

  const authToken = SessionStorageAdapter.getAccessToken();
  const featureFlagBilling = useRef<boolean>(
    process.env.REACT_APP_FEATURE_FLAG_BILLING === "true"
  ).current;

  useEffect(() => {
    if (!authToken) return;

    const fetchDataWithDelay = async () => {
      const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

      const profileNeedsFetch =
        profileState.profile.status !== "success" || profileState.paymentPreferences.status !== "success";

      if (profileNeedsFetch) {
        try {
          // Add delay before starting the fetch
          await delay(3000); // 1-second delay

          await Promise.all([
            profileState.profile.status !== "success" && fetchProfile(featureFlagBilling),
            profileState.paymentPreferences.status !== "success" && fetchPaymentPreferences(),
            fetchBillingDetails(),
          ]);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchDataWithDelay();
  }, [authToken]);

  return (
    <>
      <Outlet />
    </>
  );
};