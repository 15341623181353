import { styled } from "styled-components";

export const BoxContainer = styled.div`
    position: relative;
    height: fit-content;
    width: fit-content;
`

export const BoxIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:hover {
        cursor: pointer;
    }
`

export const Box = styled.div<{$active: boolean}>`
    background-color: ${({ $active }) => ($active ? "#101010" : "#ececec")};
    width: 20px;
    height: 20px;
    border-radius: 6px;

    transition: background-color 200ms;
    &:hover {
        background-color: ${({ $active }) => ($active ? "#353333" : "#e6e4e0")};
        cursor: pointer;
    }
`;