export enum PaymentType {
  NoPayment = "no_payment",
  PlataByMono = "plata_by_mono",
}

export type PaymentPreferences = {
  paymentTypes: string[];
  monobankKey: string;
}

export const initialPaymentPreferences: PaymentPreferences = {
  paymentTypes: [],
  monobankKey: "",
}

export const PAYMENT_OPTIONS = [
  {
    id: PaymentType.NoPayment,
    label: "Без оплати",
    description: "Ідеально для тих в кого ще немає ФОП",
  },
  {
    id: PaymentType.PlataByMono,
    label: "Plata від Monobank",
    description: "Інтеграція з платіжною системою Plata",
  },
];