import { Helmet } from "react-helmet-async";

type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
}

export const SeoHeader = (props: Props) => (
  <Helmet>
    <title>{props.title}</title>
    <meta name="description" content={props.description} />

    {/* Open Graph Meta Tags for Social Sharing */}
    <meta property="og:title" content={props.title} />
    <meta property="og:description" content={props.description} />
    <meta property="og:url" content={props.url} />
    <meta property="og:image" content={props.imageUrl} />
    <meta property="og:type" content="website" />

    {/* Twitter Card Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.description} />
    <meta name="twitter:image" content={props.imageUrl} />
  </Helmet>
);