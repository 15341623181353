import React from "react";
import { Tag } from "./styles";

export type TextTagColors = "accent" | "yellow" | "red";

export type TextTagProps = {
  color: TextTagColors;
  text: string;
}

export const TextTag = ({text, color}: TextTagProps) => (
  <Tag $color={color}>{text}</Tag>
)