import { PaymentPreferences, PaymentType } from "../../../models/PaymentPreferences";

// Payment preferences to be set up for the account
export type SetupPaymentPreferences = {
  type: IntegrationType | null;
  settings?: ApiPaymentSettings;
}

export enum IntegrationType {
  WithoutPayment = 1,
  PaymentTypeAccount = 2,
  Monobank = 3
}

export type ApiPaymentSettings = {
  [key: string]: string;
}

// Already setup payment preferences
export type AccountPaymentPreferences = {
  type: IntegrationType;
  hasSecret: boolean;
}

export const mapPaymentPreferencesToApiSetupPreferences = (prefs: PaymentPreferences): SetupPaymentPreferences => {
  let paymentPreferences: SetupPaymentPreferences = {
    type: null,
    settings: {},
  };

  switch (prefs.paymentTypes[0]) {
    case PaymentType.NoPayment:
      paymentPreferences.type = IntegrationType.WithoutPayment;
      break;

    case PaymentType.PlataByMono:
      paymentPreferences.type = IntegrationType.Monobank;
      paymentPreferences.settings = {
        secret: prefs.monobankKey,
      }
      break;
  }

  return paymentPreferences;
}

export const mapPaymentPreferencesFromApiAccountPreferences = (prefs: PaymentPreferences): AccountPaymentPreferences => {
  let paymentPreferences: AccountPaymentPreferences = {
    type: IntegrationType.WithoutPayment,
    hasSecret: false,
  };

  if (prefs.paymentTypes[0] === PaymentType.PlataByMono) {
    paymentPreferences.type = IntegrationType.Monobank;
    paymentPreferences.hasSecret = true;
  }

  return paymentPreferences;
}

export const mapApiPaymentPreferences = (prefs: AccountPaymentPreferences): PaymentPreferences => {
  let paymentPreferences: PaymentPreferences = {
    paymentTypes: [],
    monobankKey: "",
  };

  switch (prefs.type) {
    case IntegrationType.WithoutPayment:
      paymentPreferences.paymentTypes.push(PaymentType.NoPayment);
      break;

    case IntegrationType.Monobank:
      paymentPreferences.paymentTypes.push(PaymentType.PlataByMono);
      paymentPreferences.monobankKey = prefs.hasSecret ? "(приховано)" : "";
      break;
  }

  return paymentPreferences;
}