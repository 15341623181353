import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./styles/global.css";
import App from "routes";
import store from "lisa/common/store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(async () => {
  const { initDatadogRUM } = await import("monitoring/datadog/datadogRum");
  initDatadogRUM();
})();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { CheckoutLocalStorage } from "./lisa/customer/features/checkout/adapters/checkoutLocalStorage";