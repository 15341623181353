import { useEffect } from "react";
import { logPageView } from "./googleAnalytics";

const GARouteChangeListener = () => {

  useEffect(() => {
    logPageView(window.location.pathname);
  }, []);

  return null;
};

export default GARouteChangeListener;