import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --font-base: Inter, system-ui;
        --bg-icon-100: ${({ theme }) => theme.bgIcon100};
        --bg-icon-101: ${({ theme }) => theme.bgIcon101};
        --bg-icon-102: ${({ theme }) => theme.bgIcon102};
        --text-100: ${({ theme }) => theme.text100};
        --bg-lazy-image: ${({ theme }) => theme.bgLazyImage};
        --bg-primary: ${({ theme }) => theme.bgLight100};
        --text-101: ${({ theme }) => theme.text101};
        --text-102: ${({ theme }) => theme.text102};
        --text-104: ${({ theme }) => theme.text104};
        --text-105: ${({ theme }) => theme.text105};
        --text-106: ${({ theme }) => theme.text106};
        --text-107: ${({ theme }) => theme.text107};
        --text-108: ${({ theme }) => theme.text108};
        --text-109: ${({ theme }) => theme.text109};
        --text-110: ${({ theme }) => theme.text110};
        --text-111: ${({ theme }) => theme.text111};
        --text-112: ${({ theme }) => theme.text112};
        --bg-divider: ${({ theme }) => theme.bgDivider100};
        --bg-divider-101: ${({ theme }) => theme.bgDivider101};
        --bg-theme-switch: ${({ theme }) => theme.bgThemeSwitch};
        --bg-popup: ${({ theme }) => theme.bgPopup};
        --bg-popup-button: ${({ theme }) => theme.bgPopupButton};
        --bg-switch-on: ${({ theme }) => theme.bgSwitchOn};
        --bg-switch-off: ${({ theme }) => theme.bgSwitchOff};
        --bg-switch-on-hover: ${({ theme }) => theme.bgSwitchOnHover};
        --bg-switch-off-hover: ${({ theme }) => theme.bgSwitchOffHover};
        --bg-border: ${({ theme }) => theme.bgBorder};
        --bg-border-signin: ${({ theme }) => theme.bgBorderSignIn};
        --bg-choice-hover: ${({ theme }) => theme.bgChoiceHover};
        --bg-choice: ${({ theme }) => theme.bgChoice};
        --bg-input: ${({ theme }) => theme.bgInput};
        --bg-input-signin: ${({ theme }) => theme.bgInputSignIn};
        --bg-input-hover: ${({ theme }) => theme.bgInputHover};
        --bg-choive-description: ${({ theme }) => theme.bgChoiceDescription};
        --bg-back-button-hover: ${({ theme }) => theme.bgBackButtonHover};
        --bg-close-button-hover: ${({ theme }) => theme.bgCloseButtonHover};
        --bg-accent-button: ${({ theme }) => theme.bgAccentButton};
        --bg-billing-card: ${({ theme }) => theme.bgBillingCard};
        --bg-back-button: ${({ theme }) => theme.bgBackButton};
        --bg-divider-content: ${({ theme }) => theme.bgDividerContent};
        --bg-add-button: ${({ theme }) => theme.bgAddButton};
        --bg-callout: ${({ theme }) => theme.bgCallout};
        --bg-hint-error: ${({ theme }) => theme.bgHintError};
        --accent-hover: ${({ theme }) => theme.accentHover};
        --accent-active: ${({ theme }) => theme.accentActive};
    }
    
    html {
        --full-vh: calc(var(--vh) * 100);
    }

    body {
        background-color: ${({ theme }) => theme.bgLight100};
    }

    body,
    input,
    button,
    h1,
    h2,
    p,
    div,
    span {
        margin: 0;
        padding: 0;
        cursor: default;
    }

    * {
        font-family: var(--font-base);
    }

    button,
    input {
        border: none;
        outline: none;
    }

    input {
        cursor: pointer !important;

        &:focus {
            caret-color: #262626;
            outline: none;
            cursor: pointer !important;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
    }

    b {
        font-weight: 600;
    }

    ::selection {
        background-color: #202020;
        color: #d3d3d3;
    }
`;

export default GlobalStyle;
