import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeType } from "./theme";  
import { changeTheme } from "../features/ui/store/slice";
import { AppState } from "../store/store";

const getSystemTheme = () => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return prefersDark ? ThemeType.Dark : ThemeType.Light;
};

const loadThemeFromStorage = (): ThemeType => {
  try {
    const storedTheme = localStorage.getItem("theme");
    if (
      storedTheme &&
      (storedTheme === ThemeType.Light ||
        storedTheme === ThemeType.Dark ||
        storedTheme === ThemeType.Auto)
    ) {
      return storedTheme as ThemeType;
    }
  } catch (error) {
    console.error("Failed to load theme from localStorage:", error);
  }
  return ThemeType.Light;
};

const updateMetaThemeColor = (theme: ThemeType) => {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  if (metaThemeColor) {
    const themeColor = theme === ThemeType.Dark ? '#000000' : '#000';
    metaThemeColor.setAttribute('content', themeColor);
  }
};

const useTheme = (): [ThemeType, (theme: ThemeType) => void] => {
  const dispatch = useDispatch();
  const theme = useSelector((state: AppState) => state.ui.theme); 

  const applyTheme = (themeType: ThemeType) => {
    let appliedTheme = themeType;

    if (themeType === ThemeType.Auto) {
      appliedTheme = getSystemTheme();
    }

    if (appliedTheme !== theme) {
      dispatch(changeTheme(appliedTheme)); // Update theme in the global state
      localStorage.setItem("theme", themeType); // Store the preference in localStorage
      updateMetaThemeColor(appliedTheme);
    }
  };

  // Load theme from storage or apply system preference if 'auto'
  useEffect(() => {
    const savedTheme = loadThemeFromStorage();
    applyTheme(savedTheme);

    if (savedTheme === ThemeType.Auto) {
      const systemThemeQuery = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      const handleChange = (e: MediaQueryListEvent) =>
        applyTheme(e.matches ? ThemeType.Dark : ThemeType.Light);
      systemThemeQuery.addEventListener("change", handleChange);
      return () => systemThemeQuery.removeEventListener("change", handleChange);
    }
  }, []);

  return [theme, applyTheme];
};

export default useTheme;
