import React, { ReactNode, useState } from "react";
import { Box, BoxContainer, BoxIcon } from "./styles";
import Flex from "../../styles/components/Flex";

type Props = {
  // Initial active value
  active: boolean;
  click: () => void;
};

export const MarkBox = (props: Props) => {
  const {active, click} = props;

  const handleClick = (): void => {
    click();
  }

  const RenderActiveIcon = (): ReactNode => (
    <BoxIcon>
      <Flex $justify="center" $align="center" $fullWidth $fullHeight>
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.2297 0.578125L5.03737 8.05462L1.59273 5.24239L1 5.9688L5.22965 9.42196L11 1.11286L10.2297 0.578125Z"
            fill="#FFF" stroke="#FFF" strokeWidth="0.3" />
        </svg>
      </Flex>
    </BoxIcon>
  )

  return (
    <BoxContainer onClick={handleClick}>
      <Box $active={active} />
      {active && RenderActiveIcon()}
    </BoxContainer>
  );
}