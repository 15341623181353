import { ArrowLeftIcon } from "lisa/common/icons";
import { InteractiveButton } from "./Base";
import { CSSProp } from "styled-components";

type Props = {
  tid: string;
  kind: "big" | "small";
  customStyle?: CSSProp;
  onClick: () => void;
};

export const BackButton = (props: Props) => (
  <InteractiveButton
    data-test-id={props.tid}
    $kind={props.kind}
    $customStyles={props.customStyle}
    onClick={props.onClick}
  >
    <ArrowLeftIcon />
  </InteractiveButton>
);
