import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, LoadableStatus, loading, success } from "lisa/common/store/Loadable";
import { initialState } from "./BrandFeedStoreState";
import { FeedListings } from "../adapters/models/FeedListings";
import { AccountDetails } from "../adapters/models/AccountDetails";

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    fetchBrandListings(state) {
      state.listings.status = LoadableStatus.Loading;
      state.listings.error = undefined;
    },
    fetchBrandListingsSuccess(
      state,
      action: PayloadAction<{ page: number; listings: FeedListings }>
    ) {
      // It's important to skip the same page, cause in React StrictMode the app does double fetch
      if (state.page == action.payload.page) {
        state.listings.status = LoadableStatus.Success;
        return;
      }

      state.listings = success({
        items: [...state.listings.data.items, ...action.payload.listings.items],
      });
      state.page = action.payload.page;
      state.listings.error = undefined;
      state.listings.status = LoadableStatus.Success;
      state.hasMorePages = action.payload.listings.items.length > 0;
    },
    fetchBrandListingsError(state, action: PayloadAction<UIErrorCompact>) {
      state.listings = error(state.listings.data, action.payload);
    },

    fetchBrandProfile(state) {
      state.profile = loading(state.profile.data);
    },
    fetchBrandProfileSuccess(state, action: PayloadAction<AccountDetails>) {
      state.profile = success(action.payload);
    },
    fetchBrandProfileError(state, action: PayloadAction<UIErrorCompact>) {
      state.profile = error(state.profile.data, action.payload);
    },
  },
});

export const {
  fetchBrandListings,
  fetchBrandListingsSuccess,
  fetchBrandListingsError,
  fetchBrandProfile,
  fetchBrandProfileSuccess,
  fetchBrandProfileError,
} = feedSlice.actions;

export default feedSlice.reducer;
