import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, Loadable, LoadableStatus, loading } from "../../../../common/store/Loadable";
import { OrderPolicyPreferences, initialOrderPolicyPreferences } from "../models/OrderPolicyPreferences";

type OrderPolicyPreferencesState =  Loadable<OrderPolicyPreferences>

const initialState: OrderPolicyPreferencesState = loading(initialOrderPolicyPreferences);

// TODO: consider adding different actions for a particular delivery option
const orderPolicySlice = createSlice({
  name: "orderPolicyPrefs",
  initialState,
  reducers: {
    updateOrderPolicyPreferences(state) {
      state.status = LoadableStatus.Loading;
    },
    updateOrderPolicyPreferencesSuccess(state, action: PayloadAction<OrderPolicyPreferences>) {
      state.status = LoadableStatus.Success;
      state.error = undefined;
      state.data = {...state.data, ...action.payload};
    },
    updateOrderPolicyPreferencesError(state, action: PayloadAction<UIErrorCompact>) {
      state.status = LoadableStatus.Error;
      state.error = action.payload;
      state.data = {...state.data};
    }
  }
});

export const {
  updateOrderPolicyPreferences,
  updateOrderPolicyPreferencesSuccess,
  updateOrderPolicyPreferencesError,
} = orderPolicySlice.actions;

export default orderPolicySlice.reducer;