import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import {
  MediaAssetArray,
  newMediaBuilderAssetArrayFromVariants,
} from "kit/MediaEditor/MediaAsset/MediaAssetArray";
import { initialState } from "./MediaStoreState";
import { MediaVariants } from "lisa/common/features/media/models/MediaMetadata";

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    loadMedia(state, action: PayloadAction<MediaVariants[]>) {
      const assets = newMediaBuilderAssetArrayFromVariants(action.payload);
      state.media = assets;
    },
    addMedia(state, action: PayloadAction<MediaAssetArray>) {
      const updatedMediaBuilderAssets = state.media.addMedia(
        ...action.payload.getMedia()
      );
      state.media = updatedMediaBuilderAssets;
    },
    updateMedia(state, action: PayloadAction<number[]>) {
      const reorderedFiles = state.media.reorderMedia(action.payload);

      state.media = reorderedFiles;
    },
    deleteMedia(state, action: PayloadAction<number>) {
      const newMedia = state.media.deleteMedia(action.payload);
      state.media = newMedia;
    },
  },
});

export const { loadMedia, addMedia, updateMedia, deleteMedia } = mediaSlice.actions;

export default mediaSlice.reducer;
