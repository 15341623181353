import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, LoadableStatus, loading } from "../../../../common/store/Loadable";
import { initialState } from "./BillingStoreState";
import { BillingPlan, BillingPreferences } from "../adapters/billingapi/models/BillingPreferences";
import { BillingEmail } from "../adapters/billingapi/models/BillingEmail";

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    fetchBillingDetails(state) {
      state.prefs.status = LoadableStatus.Loading;
      state.prefs.error = undefined;
    },
    fetchBillingDetailsSuccess(state, action: PayloadAction<BillingPreferences>) {
      state.prefs.data = action.payload;
      state.prefs.error = undefined;
      state.prefs.status = LoadableStatus.Success;
    },
    fetchBillingDetailsError(state, action: PayloadAction<UIErrorCompact>) {
      state.prefs = error({ ...state.prefs.data }, action.payload);
    },
    startTrial(state) {
      state.prefs.status = LoadableStatus.Loading;
      state.prefs.error = undefined;
    },
    startTrialSuccess(state) {
      state.prefs.error = undefined;
      state.prefs.status = LoadableStatus.Success;
    },
    startTrialError(state, action: PayloadAction<UIErrorCompact>) {
      state.prefs = error({ ...state.prefs.data }, action.payload);
    },
    startSubscription(state) {
      state.prefs.status = LoadableStatus.Loading;
      state.prefs.error = undefined;
    },
    startSubscriptionSuccess(state, action: PayloadAction<BillingPlan>) {
      state.prefs.data.plan = action.payload;
      state.prefs.error = undefined;
      state.prefs.status = LoadableStatus.Success;
    },
    startSubscriptionError(state, action: PayloadAction<UIErrorCompact>) {
      state.prefs = error({ ...state.prefs.data }, action.payload);
    },
    cancelBilling(state) {
      state.prefs.status = LoadableStatus.Loading;
      state.prefs.error = undefined;
    },
    cancelBillingSuccess(state) {
      state.prefs.data = { ...state.prefs.data, plan: BillingPlan.None };
      state.prefs.error = undefined;
      state.prefs.status = LoadableStatus.Success;
    },
    cancelBillingError(state, action: PayloadAction<UIErrorCompact>) {
      state.prefs = error({ ...state.prefs.data }, action.payload);
    },
    updateBillingPreferences(state, action: PayloadAction<BillingEmail>) {
      state.prefs.data.email = action.payload;
    },
    fetchBillingPreferences(state) {
      state.prefs = loading(state.prefs.data);
    },
    fetchBillingPreferencesSuccess(
      state,
      action: PayloadAction<BillingPreferences>
    ) {
      state.prefs.data.email = action.payload.email;
    },
    fetchBillingPreferencesError(state, action: PayloadAction<UIErrorCompact>) {
      state.prefs = error({ ...state.prefs.data }, action.payload);
    },
  },
});

export const {
  fetchBillingDetails,
  fetchBillingDetailsSuccess,
  fetchBillingDetailsError,
  startSubscription,
  startSubscriptionError,
  startSubscriptionSuccess,
  startTrial,
  startTrialError,
  startTrialSuccess,
  cancelBilling,
  cancelBillingError,
  cancelBillingSuccess,
  updateBillingPreferences,
  fetchBillingPreferences,
  fetchBillingPreferencesSuccess,
  fetchBillingPreferencesError
} = billingSlice.actions;

export default billingSlice.reducer;
