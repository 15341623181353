import axios, { AxiosError } from "axios";
import { logger } from "lib/logger";
import { AppError, AppErrorCode, stringToAppErrorCode } from "lisa/common/adapters/apicoreapi/models";

export class RestAppError extends Error {
  public code: string;

  constructor(code: string, message: string) {
    super(`${code}: ${message}`);
    this.name = "RestAppError";
    this.code = code;
    this.message = message;
  }
}

// wrapIntoAppError is used to convert any Api errors to AppError and re-throw them
export const wrapIntoAppError = (err: unknown): AppError => {
  const error = err as Error | AxiosError;

  if (axios.isAxiosError(error)) {
    if (!error.response) {
      if (error.code === 'ERR_NETWORK') {
        return new AppError(AppErrorCode.SpecificError, "ERR_NETWORK");
      }
      logger.error("IMPORTANT: Api error with empty response");
      return new AppError(AppErrorCode.UnknownClientError, "Error has no body");
    }

    const httpBody = error.response.data;
    const httpCode = error.response.status;

    // Check if the error response is of ApiError type
    if (isRestAppError(httpBody)) {
      const { code, message } = httpBody;
      const parsedCode = stringToAppErrorCode(code);
      return new AppError(parsedCode, message, httpCode);
    }

    // In this case the error response is not of ApiError type
    logger.error(`IMPORTANT: Invalid error response format: ${JSON.stringify(httpBody)}`);
    return new AppError(AppErrorCode.UnknownServerError, `Unknown server error format: ${JSON.stringify(httpBody)}`);
  }

  logger.error("IMPORTANT: An unknown error occurred");
  return new AppError(AppErrorCode.UnknownClientError, `An unknown error occurred: ${error.message}`);
};

const isRestAppError = (data: any): data is RestAppError => {
  return data && typeof data.code === 'string' && typeof data.message === 'string';
};