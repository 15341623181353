import { AppDispatch } from "lisa/common/store/store";
import { UIError } from "lisa/common/store/UIError";
import AccountsApiInstance from "../adapters/accountsapi/AccountsApi";
import { SnackBarInfo } from "lisa/common/components/SnackBar/SnackBarInfo";
import { showSnackBarWithTimeoutThunk } from "lisa/common/features/ui/store/thunks";
import { updatePaymentPreferencesForProfile } from "./profileSlice";
import { PaymentPreferences } from "../models/PaymentPreferences";
import {
  mapPaymentPreferencesFromApiAccountPreferences, mapPaymentPreferencesToApiSetupPreferences
} from "../adapters/accountsapi/models/SetupPaymentPreferences";
import {
  updatePaymentPreferences,
  updatePaymentPreferencesError,
  updatePaymentPreferencesSuccess
} from "./paymentPreferencesSlice";
import { fetchProfileThunk } from "./profileThunks";

export const updatePaymentPreferencesThunk = (prefs: PaymentPreferences) => async (dispatch: AppDispatch) => {
  dispatch(updatePaymentPreferences());

  try {
    const apiPrefs = mapPaymentPreferencesToApiSetupPreferences(prefs);
    const prefsForProfile = mapPaymentPreferencesFromApiAccountPreferences(prefs);
    await AccountsApiInstance.updatePaymentPreferences(apiPrefs);
    dispatch(updatePaymentPreferencesSuccess(prefs));

    // It's important to fetch the updated profile preferences, as after changing
    // the payment preferences, it might impact other preferences, and we should
    // reflect those changes on the ui.
    dispatch(fetchProfileThunk(false))
    dispatch(updatePaymentPreferencesForProfile(prefsForProfile));

    const snackBarInfo: SnackBarInfo = {
      title: "Налаштування збережено",
      description: "Ваші зміни збережено",
      actionName: "Закрити",
      isError: false
    }
    dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
  }  catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      // TODO: Implement custom error handling
      // if (appError.code === AppErrorCode.BadRequest) {
      //   return "Невірний формат телефону";
      // }
      return undefined;
    });

    const snackBarInfo: SnackBarInfo = {
      title: "Помилка",
      description: uiError.getMessage(),
      actionName: "Закрити",
      isError: true
    }
    dispatch(showSnackBarWithTimeoutThunk(snackBarInfo));
    dispatch(updatePaymentPreferencesError(uiError));
  }
}