import Flex from "lisa/common/styles/components/Flex";
import { HeaderContainer } from "./styles";
import { Heading } from "lisa/common/styles/components/Typography/Heading";
import { useRef } from "react";
import { Link } from "react-router-dom";

const SignInHeader = () => {
  const spottedFeatureFlag = useRef<boolean>(
    process.env.REACT_APP_SPOTTED_FEATURE_FLAG === "true"
  ).current;

  return (
    <HeaderContainer>
      <Flex $justify="left">
        <Link to="/" data-test-id="logo">
          <Heading
            $size="medium"
            style={{ letterSpacing: "-1px" }}
            $color="headerMenu"
          >
            {spottedFeatureFlag ? "spotted" : "brandlink"}
          </Heading>
        </Link>
      </Flex>
    </HeaderContainer>
  );
};

export default SignInHeader;
