import { useEffect, useState } from "react";

/**
 * useViewportHeight
 *
 * This hook calculates the viewport height dynamically and sets it as a CSS variable `--vh`.
 * It helps address the issue where `100vh` in CSS includes the browser UI (e.g., address bar on mobile),
 * which can lead to incorrect layout sizing, particularly on iOS devices.
 *
 * The hook is SSR-safe by ensuring that it only runs on the client side where `window` is available.
 *
 * How it works:
 * 1. Calculates `1vh` as `window.innerHeight * 0.01`.
 * 2. Updates the value dynamically when the window is resized.
 * 3. Sets the result as a CSS variable `--vh` on the `html` element for global use.
 *
 * Usage:
 * Call `useViewportHeight()` in a root or relevant React component.
 * Then, use `var(--vh)` in your styled ListingDetails or CSS to achieve dynamic height.
 */
export const useViewportHeight = () => {
  const isClient = typeof window !== "undefined";
  const [vh, setVh] = useState(isClient ? document.documentElement.clientHeight * 0.01 : 0);

  useEffect(() => {
    if (!isClient) return;

    const updateVh = () => {
      const newVh = document.documentElement.clientHeight * 0.01;
      setVh(newVh);
      document.documentElement.style.setProperty("--vh", `${newVh}px`);
    };

    updateVh(); // Set initial value
    window.addEventListener("resize", updateVh);
    return () => window.removeEventListener("resize", updateVh);
  }, [isClient]);

  return vh;
};