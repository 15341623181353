import { AnyAction, combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import authenticationReducer from "lisa/common/features/authentication/store/slice";
import uiReducer from "lisa/common/features/ui/store/slice";
import profileReducer from "lisa/merchant/features/profile/store/profileSlice";
import listingsReducer from "lisa/merchant/features/listings/store/slice";
import mediaReducer from "lisa/merchant/features/media/store/slice";
import orderReducer from "lisa/merchant/features/order/store/slice";
import shopPreferencesReducer from "lisa/merchant/features/profile/store/shopPreferencesSlice";
import orderPolicyReducer from "lisa/merchant/features/profile/store/deliveryPreferencesSlice";
import paymentPreferencesReducer from "lisa/merchant/features/profile/store/paymentPreferencesSlice";
import feedReducer from "lisa/customer/features/feed/store/slice";
import billingReducer from "lisa/merchant/features/billing/store/slice";
import checkoutReducer from "lisa/customer/features/checkout/store/slice";

const appReducer = combineReducers({
  // Common reducers
  authentication: authenticationReducer,
  ui: uiReducer,

  // Merchant reducers
  profile: profileReducer,
  shopPreferences: shopPreferencesReducer,
  orderPolicyPreferences: orderPolicyReducer,
  paymentPreferences: paymentPreferencesReducer,
  listings: listingsReducer,
  media: mediaReducer,
  order: orderReducer,
  customerBrandFeed: feedReducer,
  billing: billingReducer,
  checkout: checkoutReducer,
});

// Root reducer with reset logic
const rootReducer: Reducer<ReturnType<typeof appReducer>, AnyAction> = (state, action) => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action); // Reset state to initial values
  }
  return appReducer(state, action); // Handle other actions normally
};

// Create the store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'order.activeOrder.data.value.createdAt',
          'media.media',
          'listings.newProduct.data.assets'
        ],
      },
    }),
});

export type RootState = ReturnType<typeof appReducer>;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

export const resetState = () => ({ type: "RESET_STATE" });
