import { Loadable, loading } from "lisa/common/store/Loadable";
import { FeedListings } from "../adapters/models/FeedListings";
import { AccountDetails } from "../adapters/models/AccountDetails";
import { PaymentMode } from "../adapters/models/PaymentMode";
import {
  IntegrationType
} from "lisa/merchant/features/profile/adapters/accountsapi/models/SetupPaymentPreferences";

export type BrandFeedStoreState = {
  listings: Loadable<FeedListings>;
  page: number;
  profile: Loadable<AccountDetails>;
  hasMorePages: boolean;
};

export const initialState: BrandFeedStoreState = {
  listings: loading({
    items: [],
  }),
  page: 0,
  profile: loading({
    displayName: "",
    delivery: {},
    hasPolicies: false,
    private: false,
    playgroundMode: false,
    paymentIntegration: IntegrationType.WithoutPayment,
    paymentMode: PaymentMode.AfterShipping,
  }),
  hasMorePages: false,
};
