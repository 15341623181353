import { styled } from "styled-components";

export const HeaderContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px 60px;
    background-color: transparent;
    z-index: 6;
  }
`;
