export enum AppErrorCode {
  UnknownClientError = "UNKNOWN_CLIENT_ERROR",
  UnknownServerError = "UNKNOWN_SERVER_ERROR",
  BadRequest = "BAD_REQUEST",
  Unauthorized = "UNAUTHORIZED",
  Forbidden = "FORBIDDEN",
  NotFound = "NOT_FOUND",
  Conflict = "CONFLICT",
  Gone = "GONE",
  UnprocessableEntity = "UNPROCESSABLE_ENTITY",
  TooManyRequests = "TOO_MANY_REQUESTS",
  InternalServerError = "INTERNAL",

  SpecificError = "SPECIFIC_ERROR",
}

export const stringToAppErrorCode = (code: string): AppErrorCode => {
  switch (code) {
    case "UNKNOWN_CLIENT_ERROR":
      return AppErrorCode.UnknownClientError;
    case "UNKNOWN_SERVER_ERROR":
      return AppErrorCode.UnknownServerError;
    case "BAD_REQUEST":
      return AppErrorCode.BadRequest;
    case "UNAUTHORIZED":
      return AppErrorCode.Unauthorized;
    case "FORBIDDEN":
      return AppErrorCode.Forbidden;
    case "NOT_FOUND":
      return AppErrorCode.NotFound;
    case "CONFLICT":
      return AppErrorCode.Conflict;
    case "GONE":
      return AppErrorCode.Gone;
    case "UNPROCESSABLE_ENTITY":
      return AppErrorCode.UnprocessableEntity;
    case "TOO_MANY_REQUESTS":
      return AppErrorCode.TooManyRequests;
    case "INTERNAL":
      return AppErrorCode.InternalServerError;
    case "SPECIFIC_ERROR":
      return AppErrorCode.SpecificError;
  }

  throw new AppError(AppErrorCode.UnknownServerError, `Unknown error code: ${code}`);
};

export class AppError extends Error {
  public code: AppErrorCode;
  public status: number | undefined;

  // Retry used to prevent an infinite loop
  // on the HTTP client interceptor layer
  private retry = true;

  constructor(code: AppErrorCode, message: string, status?: number) {
    super(`${code}: ${message}`);

    this.name = "AppError";
    this.code = code;
    this.message = message;

    if (status) {
      this.status = status;
    }
  }

  // public stopRetry(): void {
  //   this.retry = false;
  // }
  //
  // public canRetry(): boolean {
  //   return this.retry;
  // }
  //
  // public isUnauthorized(): boolean {
  //   return this.code === AppErrorCode.Unauthorized;
  // }
  //
  // public isForbidden(): boolean {
  //   return this.code === AppErrorCode.Forbidden;
  // }
}