import { MediaErrorType } from "kit/common/mediaValidationRules";

export type MediaMetadata = {
  id: string;
  type: string;
  error: MediaErrorType | null;
};

// --------------------------------------------
export type MediaType = "photo" | "video";

export type Variant = {
  url: string;
  width?: number; // width is optional for video media type
}

export type MediaVariants = {
  // id is a unique identifier of the media in ulid format
  id: string;
  type: MediaType;
  variants: Variant[];
}

export const getMinMediaUrl = (media: MediaVariants): string | null => {
  const result = media.variants.filter((value) => value.width == 128);
  return result.length > 0 ? result[0].url : null;
}

// Extracts ulid from the image URL: https://media.example.com/01J31204AS7WGMYSJJQMZ8TEPC/128.jpg
export const extractImageMediaId = (imageUrl: string): string => {
  const urlObj = new URL(imageUrl);
  const pathname = urlObj.pathname; // Gets the pathname part of the URL (e.g., /01J31204AS7WGMYSJJQMZ8TEPC/128.jpg)
  const parts = pathname.split('/'); // Splits the pathname into parts
  const ulid = parts[1]; // The ULID is the second part of the pathname
  return ulid;
}