import { MediaAsset, RawMediaAsset, StorageMediaAsset } from "./MediaAsset";
import { MAX_MEDIA_QUANTITY, MediaErrorType } from "../../common/mediaValidationRules";
import { MediaVariants } from "../../../lisa/common/features/media/models/MediaMetadata";

export class MediaAssetArray {
  private media: MediaAsset[];

  constructor(media: MediaAsset[] = []) {
    this.media = media;
  }

  public getMedia(): MediaAsset[] {
    return this.media;
  }

  public getAssetFiles(): File[] {
    const files = [];
    for (const mediaItem of this.media) {
      if (mediaItem.file) {
        files.push(mediaItem.file);
      }
    }

    return files;
  }

  public async validate(): Promise<void> {
    for (const mediaItem of this.media) {
      await mediaItem.validate(); 
    }
  }

  public addMedia(...newMediaItems: MediaAsset[]): MediaAssetArray {
    return new MediaAssetArray([...this.media, ...newMediaItems]);
  }

  public reorderMedia(newIndexes: number[]): MediaAssetArray {
    return new MediaAssetArray(newIndexes.map((index) => this.media[index]));
  }

  public deleteMedia(index: number): MediaAssetArray {
    return new MediaAssetArray(this.media.filter((_, i) => i !== index));
  }

  public getMediaBuilderErrors(): MediaErrorType[] {
    const errors: MediaErrorType[] = [];

    // Ensure that there is no more than one video file
    const videoFiles = this.media.filter((asset) =>
      asset.type.startsWith("video")
    );
    if (videoFiles.length > 1) {
      errors.push(MediaErrorType.WrongVideosQuantity);
    }

    if (this.media.length === 1 && videoFiles.length === 1) {
      errors.push(MediaErrorType.NoPhotos)
    }

    if (
      this.media.length > MAX_MEDIA_QUANTITY ||
      this.media.length === 0
    ) {
      errors.push(MediaErrorType.WrongPhotosQuantity);
    }

    // Check media asset errors
    const assetErrors = this.media.map((mediaItem) => mediaItem.error).filter((error) => error !== null) as MediaErrorType[];
    if(assetErrors.length > 0) {
      errors.push(...assetErrors);
    }

    return errors;
  }

  public get length(): number {
    return this.media.length;
  }

  public equal(o: MediaAssetArray): boolean {
    const otherMedia = o.getMedia();
    if (this.media.length !== o.getMedia().length) return false;

    return this.media.every(
      (item, index) => item.url === otherMedia[index].url
    );
  }
}

export const newMediaBuilderAssetArrayFromFiles = (files: File[]): MediaAssetArray => {
  const media = files.map((file) => new RawMediaAsset(file));
  return new MediaAssetArray(media);
}

export const newMediaBuilderAssetArrayFromVariants = (mediaMeta: MediaVariants[]): MediaAssetArray => {
  const media = mediaMeta.map((meta) => {
    return new StorageMediaAsset(meta.variants[0].url, meta.type)
  });
  return new MediaAssetArray(media);
}