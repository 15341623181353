import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserSession } from "./models/UserSession";
import { SessionStorageAdapter } from "./adapters/SessionStorageAdapter";

export const SessionGuard = () => {
  const UNAUTHORIZED_PATH = "/lisa/signin";
  const AUTHORIZED_PATH = "/lisa/merchant";
  const NO_ACCOUNT_PATH = "/lisa/account/new";

  const location = useLocation();
  const [session, setSession] =
    useState<UserSession>({ hasAccessToken: false, hasAccount: false, loading: true })

  useEffect(() => {
    onStorageChange();

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);

  const onStorageChange = () => {
    const session = SessionStorageAdapter.getSession();
    setSession(session);
  }

  if (session.loading) return <div>Завантаження...</div>;
  if (!session.hasAccessToken) return <Navigate to={UNAUTHORIZED_PATH} replace />;
  if (!session.hasAccount && location.pathname !== NO_ACCOUNT_PATH)
    return <Navigate to={NO_ACCOUNT_PATH} replace />;

  // Skip welcome page if account is already created
  if (session.hasAccount && location.pathname === NO_ACCOUNT_PATH)
    return <Navigate to={AUTHORIZED_PATH} replace />;

  return <Outlet />;
}