import { UserSession } from "../models/UserSession";

export class SessionStorageAdapter {
  private static ACCESS_TOKEN_KEY = "SESSION";
  private static IDENTITY_COMPLETE_KEY = "id_complete";

  public static setSessionDetails(accessToken: string, isIdentityComplete: boolean): void {
    localStorage.setItem(SessionStorageAdapter.ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(SessionStorageAdapter.IDENTITY_COMPLETE_KEY, `${isIdentityComplete}`);
  }

  public static setAccessToken(accessToken: string): void {
    localStorage.setItem(SessionStorageAdapter.ACCESS_TOKEN_KEY, accessToken);
  }

  public static getSession(): UserSession {
    return {
      loading: false,
      hasAccessToken: this.hasAccessToken(),
      hasAccount: this.hasAccountComplete(),
    }
  }

  public static getAccessToken(): string {
    const accessToken = localStorage.getItem(SessionStorageAdapter.ACCESS_TOKEN_KEY);
    if (accessToken !== null) {
      return accessToken;
    }
    return "";
  }

  public static getBearerToken(): string {
    return `Bearer ${this.getAccessToken()}`;
  }

  public static hasAccessToken(): boolean {
    return this.getAccessToken().length > 0;
  }

  public static hasAccountComplete(): boolean {
    return localStorage.getItem(SessionStorageAdapter.IDENTITY_COMPLETE_KEY) === "true";
  }

  public static setAccountComplete(): void {
    localStorage.setItem(SessionStorageAdapter.IDENTITY_COMPLETE_KEY, "true");
  }

  public static removeAll(): void {
    localStorage.removeItem(SessionStorageAdapter.ACCESS_TOKEN_KEY);
    localStorage.removeItem(SessionStorageAdapter.IDENTITY_COMPLETE_KEY);
  }
}