//-------------------------------------------------------------------------
// Atoms
import styled, { css, CSSProp } from "styled-components";
import { mixinFlexCenter } from "../Flex";

export const mixinBase = css`
  background-color: ${({ theme }) => theme.bgLight100};
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  font-size: ${(props) => props.theme.sizes.text.regular};
  font-weight: 400;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const mixinFullWidth = css`
  width: 100%;
`;

export const mixinAccent = css`
  background-color: ${({ theme }) => theme.text100};
  color: ${({ theme }) => theme.text102};

  &:hover {
    background-color: ${(props) => props.theme.accentHover};
  }
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.accentActive};
  }
  &:disabled {
    background-color: ${(props) => props.theme.accentDisabled};
  }
`;

export const mixinBigButton = css`
  padding: 20px 24px;
  border-radius: 40px;
`;

export const mixinInteractiveButton = css`
  color: #3f3f3f;
  background-color: #f3f3f3;

  &:focus,
  &:active {
    background-color: #eaeaea;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const mixinBorderedButton = css`
  background-color: #ffffff;
  color: var(--text-100);
  border: 1px solid #e2e2e2;
  transition: all 0.2s;

  &:focus,
  &:active {
    background-color: #eaeaea;
  }

  &:hover {
    background-color: #f6f6f6;
  }
`;

//-------------------------------------------------------------------------
// Components
export const BaseButton = styled.button<{
  $fullWidth?: boolean;
  $customStyles?: CSSProp;
  $sharp?: boolean;
}>`
  ${mixinBase};
  ${(props) => props.$fullWidth ? mixinFullWidth : css`width: fit-content` };
  ${({ $sharp }) => $sharp && `border-radius: 12px`};
  ${({ $customStyles }) => $customStyles && css`${$customStyles}`};
`;

export const InteractiveButton = styled(BaseButton)<{ $kind: "big" | "small" }>`
  ${mixinFlexCenter};
  ${mixinInteractiveButton};

  padding: ${(props) => (props.$kind === "big" ? "16px" : "10px")};
  border-radius: 50px;

  ${({ $customStyles }) =>
    $customStyles &&
    css`
      ${$customStyles}
    `};
`;
