import Compressor from "compressorjs";
import {
  isMediaError,
  MediaErrorType,
} from "../../common/mediaValidationRules";
import { ulid } from "ulidx";

export const BYTES = 1;
export const KB = 1024 * BYTES;
export const MB = 1024 * KB;

export interface MediaAsset {
  url: string;
  id: string;
  // Optional original file object, used while adding the new media
  file?: File;
  error: MediaErrorType | null;
  type: string;

  validate(): Promise<MediaErrorType | null>;
}

export class StorageMediaAsset implements MediaAsset {
  url: string;
  id: string;
  error: MediaErrorType | null = null;
  type: string;

  constructor(url: string, mimeType: string) {
    this.url = url;
    this.id = this.extractIdFromUrl(url);
    this.type = mimeType;
  }

  public validate(): Promise<MediaErrorType | null> {
    return Promise.resolve(null);
  }

  private extractIdFromUrl(url: string): string {
    const parts = url.split("/");
    const secondLastPart = parts[parts.length - 2];
    return secondLastPart;
  }
}

// TODO: add close method to release resources used by URL.createObject() https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static#
export class RawMediaAsset implements MediaAsset {
  url: string;
  file: File;
  error: MediaErrorType | null;
  type: string;
  id: string;

  constructor(file: File) {
    this.url = URL.createObjectURL(file);
    this.file = file;
    this.type = file.type;
    this.error = null;
    this.id = ulid();
  }

  public async validate(): Promise<MediaErrorType | null> {
    this.error = await isMediaError(this.file);
    return this.error;
  }

  private getCompressionQuality(fileSize: number): number {
    if (fileSize <= 5 * MB) {
      return 0.9;
    }

    if (fileSize <= 10 * MB) {
      return 0.8;
    }

    return 0.75;
  }

  public compressFile(): Promise<File> {
    return new Promise((resolve) => {
      new Compressor(this.file, {
        quality: this.getCompressionQuality(this.file.size),
        success: (compressedFile) => {
          const compressedFileAsFile = new File([compressedFile], this.file.name, {
            type: compressedFile.type,
            lastModified: this.file.lastModified,
          });
          resolve(compressedFileAsFile);
        },
        error: (err) => {
          console.error('Compression error:', err);
          resolve(this.file);
        },
      });
    });
  }

  public static async compressFiles(files: File[]): Promise<File[]> {
    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith('video/')) {
          return file;
        }
        const rawMediaAsset = new RawMediaAsset(file);
        return await rawMediaAsset.compressFile(); 
      })
    );
    return compressedFiles;
  }
}

export const newMediaAsset = (
  media: string | File,
  mimeType?: string,
  error?: MediaErrorType | null
): MediaAsset => {
  if (typeof media === "string") {
    if (!mimeType) {
      throw new Error("mimeType is required for StorageMediaAsset");
    }
    return new StorageMediaAsset(media, mimeType);
  } else if (media instanceof File) {
    return new RawMediaAsset(media);
  }

  throw new Error("Invalid media type to build MediaAsset");
};

