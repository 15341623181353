import { Description, Title } from "./styles";
import React, { ReactNode } from "react";
import { CardError } from "../Choice/styles";
import { Error } from "../../styles/components/Form/Error";
import Flex, { Gap } from "../../styles/components/Flex";
import { MarkBox } from "../MarkBox";

export type ChoiceBulletOption = {
  id: string;
  label: string;
  description: string;
  BottomElement?: ReactNode;
};

type Props = {
  options: ChoiceBulletOption[];
  selectedOptions: string[];
  error: string | undefined;

  onSelect: (option: string) => void;
}

export const ChoiceBullet = (props: Props) => {
  const { options, selectedOptions, error, onSelect } = props;

  return (
    <Gap $gap={16}>
      <Gap $gap={24}>
        {options.map((option, index) => {
          const isActive = selectedOptions.includes(option.id);

          return (
            <Flex key={index} $direction="row" $gap={12}>
              <MarkBox
                active={isActive}
                click={() => onSelect(option.id)}
              />
              <div style={{ marginTop: "1px" }}>
                <Gap $gap={10}>
                  <Title onClick={() => onSelect(option.id)}>
                    {option.label}
                  </Title>
                  <Description $active={isActive}>{option.description}</Description>
                  {option.BottomElement}
                </Gap>
              </div>
            </Flex>
          );
        })}
      </Gap>
      {error && <CardError>
        <Error text={error} />
      </CardError>}
    </Gap>
  );
};