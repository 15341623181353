import {BillingHttpApi, IBillingApi} from "./api";
import {BillingFakeApi} from "./fakeapi";

if (!process.env.REACT_APP_BILLING_API_URL) {
  throw new Error("REACT_APP_BILLING_API_URL is not defined");
}

// const billingApi: IBillingApi = new BillingHttpApi(process.env.REACT_APP_BILLING_API_URL);
const billingApi: IBillingApi = new BillingFakeApi();

export { billingApi };
