import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationDetails, initialAuthenticationState, SignInMethod, SignInStep } from "../models/SignIn";
import { Access } from "../adapters/models";
import { UIErrorCompact } from "../../../store/UIError";
import { empty, Loadable, LoadableStatus } from "../../../store/Loadable";

const initialState: Loadable<AuthenticationDetails> = empty(initialAuthenticationState);

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    navigate(state, action: PayloadAction<{ method: SignInMethod, step: SignInStep }>) {
      state.error = undefined;
      state.status = LoadableStatus.Success;
      state.data = {
        ...state.data,
        method: action.payload.method,
        step: action.payload.step
      };
    },
    verifyPhoneWithSms(state) {
      state.status = LoadableStatus.Loading;
      state.error = undefined;
    },
    verifyPhoneWithSmsSuccess(state, action: PayloadAction<{ phone: string; session: string }>) {
      state.status = LoadableStatus.Success;
      state.data = {
        ...state.data,
        method: SignInMethod.Sms,
        step: SignInStep.CodeVerification,
        challengeToken: action.payload.session,
        sms: {
          ...state.data.sms,
          phone: action.payload.phone,
        }
      };
      state.error = undefined;
    },
    verifyPhoneWithSmsError(state, action: PayloadAction<UIErrorCompact>) {
      state.status = LoadableStatus.Error;
      state.error = action.payload;
    },
    verifyChallenge(state) {
      state.status = LoadableStatus.Loading;
      state.error = undefined;
    },
    verifyChallengeSuccess(state, action: PayloadAction<Access>) {
      state.status = LoadableStatus.Success;
      state.error = undefined;
      state.data = initialAuthenticationState
    },
    verifyChallengeError(state, action: PayloadAction<UIErrorCompact>) {
      state.status = LoadableStatus.Error;
      state.error = action.payload;
    },
  },
});

export const {
  navigate,

  verifyPhoneWithSms,
  verifyPhoneWithSmsSuccess,
  verifyPhoneWithSmsError,

  verifyChallenge,
  verifyChallengeSuccess,
  verifyChallengeError,
} = authenticationSlice.actions;

export default authenticationSlice.reducer