import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, LoadableStatus } from "../../../../common/store/Loadable";
import { initialState } from "./OrdersStoreState";
import { OrderState } from "../adapters/ordersapi/models/OrderState";
import { OrderThumbnail } from "../adapters/ordersapi/models/OrderThumbnail";
import { Order } from "../adapters/ordersapi/models/Order";

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    fetchThumbnails(
      state,
      action: PayloadAction<{
        state: OrderState;
        page: number;
      }>
    ) {
      state.thumbnails.status = LoadableStatus.Loading;
      state.thumbnails.error = undefined;
      const prevState = state.thumbnails.data.state;
      const isNewState = prevState !== action.payload.state;

      state.thumbnails.data.state = action.payload.state;

      if (isNewState || action.payload.page === 1) {
        state.thumbnails.data.orders = [];
      }
    },
    fetchThumbnailsSuccess(state, action: PayloadAction<OrderThumbnail[]>) {
      state.thumbnails.data.orders = [
        ...state.thumbnails.data.orders,
        ...action.payload,
      ];
      state.thumbnails.error = undefined;
      state.thumbnails.status = LoadableStatus.Success;
    },
    fetchThumbnailsError(state, action: PayloadAction<UIErrorCompact>) {
      state.thumbnails = error({ ...state.thumbnails.data }, action.payload);
    },
    changeOrderState(
      state,
      action: PayloadAction<{
        orderState: OrderState;
        cancelReason?: string | undefined;
      }>
    ) {
      state.activeOrder.data.updatedState = action.payload.orderState;
      state.activeOrder.data.cancelReason = action.payload.cancelReason;
      state.activeOrder.error = undefined;
      state.activeOrder.status = LoadableStatus.Success;
    },
    fetchOrder(state) {
      state.activeOrder.status = LoadableStatus.Loading;
      state.activeOrder.error = undefined;
    },
    fetchOrderSuccess(state, action: PayloadAction<Order>) {
      state.activeOrder.data.value = action.payload;
      state.activeOrder.data.updatedState = action.payload.state;
      state.activeOrder.status = LoadableStatus.Success;
      state.activeOrder.error = undefined;
    },
    fetchOrderError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeOrder = error({ ...state.activeOrder.data }, action.payload);
    },
    findAndUpdateOrderStatus(state, action: PayloadAction<{ id: string; state: OrderState }>) {
      if (state.thumbnails.data) {
        state.thumbnails.data.orders = state.thumbnails.data.orders.map((thumbnail) =>
          thumbnail.id === action.payload.id
            ? { ...thumbnail, state: action.payload.state }
            : thumbnail
        );
      }
    },
    updateOrderStatus(state) {
      state.activeOrder.status = LoadableStatus.Loading;
      state.activeOrder.error = undefined;
    },
    updateOrderStatusSuccess(state, action: PayloadAction<OrderState>) {
      state.activeOrder.data.value.state = action.payload;
      state.activeOrder.data.updatedState = action.payload;
      state.activeOrder.status = LoadableStatus.Success;
      state.activeOrder.error = undefined;
    },
    updateOrderStatusError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeOrder = error({ ...state.activeOrder.data }, action.payload);
    },
  },
});

export const {
  fetchThumbnails,
  fetchThumbnailsSuccess,
  fetchThumbnailsError,
  changeOrderState,
  fetchOrder,
  fetchOrderError,
  fetchOrderSuccess,
  updateOrderStatus,
  updateOrderStatusError,
  updateOrderStatusSuccess,
  findAndUpdateOrderStatus
} = ordersSlice.actions;

export default ordersSlice.reducer;
