import React from "react";
import styled from "styled-components";

type Props = {
  as: React.ElementType;
  $size:
    | "default"
    | "normal"
    | "large"
    | "big"
    | "mediumBig"
    | "medium"
    | "mediumSmall"
    | "small"
    | "tiny";
  $color?:
    | "black"
    | "grey"
    | "mediumGrey"
    | "lightGrey"
    | "white"
    | "headerMenu"
    | "preferences"
    | "saveButton"
    | "preferencesButton"
    | "preferencesTitle"
    | "preferencesDescription"
    | "headerLogo";
  $bold?: boolean;
  $textDecoration?: "underline" | "line-through" | "none";
};

export const Heading = styled.div<Props>`
    cursor: default;
    color: ${({ $color }) => {
    switch ($color) {
      case "white":
        return "#fff";
      case "black":
        return "var(--text-100)";
      case "grey":
        return "rgba(58, 58, 58, 1)";
      case "mediumGrey":
        return "rgba(111, 111, 111, 1)";
      case "lightGrey":
        return "rgba(163, 163, 163, 1)";
      case "headerMenu":
        return (props) => props.theme.text105;
      case "preferences":
        return "#8D8B86";
      case "preferencesButton":
        return "var(--text-107)";
      case "preferencesTitle":
        return "var(--text-108)";
      case "preferencesDescription":
        return "var(--text-109)";
      case "saveButton":
        return "var(--text-110)";
      case "headerLogo":
        return "var(--text-105)"
      default:
        return "var(--text-100)";
    }
  }};
  font-weight: ${({ $bold }) => ($bold ? 600 : 500)};
  font-size: ${({ $size }) => {
    switch ($size) {
      case "large":
        return "1.875rem"; // 30px
      case "big":
        return "1.5rem"; // 24px
      case "mediumBig":
        return "1.375rem"; // 22px
      case "medium":
        return "1.2rem"; // 20px
      case "mediumSmall":
        return "1.0625rem"; // 17px
      case "default":
        return "1rem"; // 16px
      case "normal":
        return "0.95rem"; // 15.2px
      case "small":
        return "0.875rem"; // 14px
      case "tiny":
        return "0.75rem"; // 12px
    }
  }};
  text-decoration: ${({ $textDecoration }) => {
    switch ($textDecoration) {
      case "underline":
        return "underline";
      case "line-through":
        return "line-through";
      default:
        return "none";
    }
  }};
`;
