import { BackButton, AccentButton } from "lisa/common/styles/components/Button";
import { Gap } from "lisa/common/styles/components/Flex";
import { Heading } from "lisa/common/styles/components/Typography/Heading";
import { useNavigate } from "react-router-dom";
import {
  BaseContainer,
  BackButtonContainer,
  backButtonStyles,
  TextContainer,
} from "../styles";
import { Text } from "lisa/common/styles/components/Typography/Text";
import { Input } from "lisa/common/styles/components/Form";
import { Formik, FormikProps } from "formik";
import { phoneValidationSchema } from "../validation";
import { ReactNode } from "react";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { SignInStep, SignInMethod } from "../models/SignIn";
import { Error } from "lisa/common/styles/components/Form/Error";

type Props = {
  loading: boolean;
  error: UIErrorCompact | null;

  phone: string;

  navigate: (method: SignInMethod, step: SignInStep) => void;
  onSubmitPhone: (phone: string) => void;
};

const WidgetInitial: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { loading, error, phone, onSubmitPhone } = props;

  const back = () => navigate("/");

  const submitPhone = (phoneValue: string) => {
    onSubmitPhone(phoneValue);
  };

  const renderError = (tid: string): ReactNode => {
    return error !== null ? <Error tid={tid} text={error.message} /> : null;
  };

  const renderPhoneForm = () => {
    // Validate if the code has prefix or not
    let phoneValue = phone;

    if (phoneValue.startsWith("380")) {
      phoneValue = phoneValue.slice(3);
    }

    const initialValues = {
      phone: phoneValue,
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={phoneValidationSchema}
        onSubmit={(values) => submitPhone(values.phone)}
        validateOnBlur
      >
        {({
          values,
          handleSubmit,
          isValid,
          dirty,
        }: FormikProps<typeof initialValues>) => {
          let disabled = false;
          if (values.phone.length > 0 && isValid) {
            disabled = false;
          } else if (!isValid || !dirty) {
            disabled = true;
          }

          return (
            <form onSubmit={handleSubmit} data-test-id={"phone-form"}>
              <Gap $gap={16}>
                <div>
                  <Input
                    tid="phone-input"
                    name="phone"
                    placeholder="Номер телефону"
                    PrefixElement="+380"
                    type="tel"
                    prefixGap={4}
                    autoFocus
                  />
                  {renderError("phone-form-error")}
                </div>
                <AccentButton
                  tid="submit-btn"
                  title="Отримати смс код"
                  disabled={disabled}
                  loading={false}
                  type="submit"
                  fullWidth
                />
              </Gap>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <BaseContainer>
      <BackButtonContainer>
        <BackButton
          tid="back-btn"
          kind="big"
          onClick={back}
          customStyle={backButtonStyles}
        />
      </BackButtonContainer>
      <Gap $gap={60}>
        <TextContainer>
          <Gap $gap={12}>
            <Heading as="h1" $size="medium">
              Увійти
            </Heading>
            <Text $size="small" $color="signInDescription">
              Ми надішлемо вам смс з кодом для входу за номером телефону
            </Text>
          </Gap>
        </TextContainer>
        <Gap $gap={16}>
          {renderPhoneForm()}
        </Gap>
      </Gap>
    </BaseContainer>
  );
};

export default WidgetInitial;


