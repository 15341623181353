import { Loadable, loading } from "../../../../common/store/Loadable";
import { BillingPlan, BillingPreferences } from "../adapters/billingapi/models/BillingPreferences";

export type BilllingStoreState = {
  prefs: Loadable<BillingPreferences>;
};

export const initialState: BilllingStoreState = {
  prefs: loading({ email: "", plan: BillingPlan.None, nextBillingDate: "" }),
};
