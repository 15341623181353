import { apicoreApi, RestApi } from "lisa/common/adapters/apicoreapi";
import { Profile } from "./models/Profile";
import { ApiDeliveryPreferences } from "./models/ApiDeliveryPreferences";
import { AccountPaymentPreferences, SetupPaymentPreferences } from "./models/SetupPaymentPreferences";
import { AccountProfile } from "./models/AccountProfile";

export interface IAccountsApi {
  getProfile(): Promise<Profile>;
  getPaymentPreferences(): Promise<AccountPaymentPreferences>;
  updateDeliveryPreferences(preferences: ApiDeliveryPreferences): Promise<void>;
  updatePaymentPreferences(preferences: SetupPaymentPreferences): Promise<void>;
  updateAccount(account: AccountProfile): Promise<void>;
}

export class AccountsApi implements IAccountsApi {
  private readonly api: RestApi;

  constructor(api: RestApi) {
    this.api = api;
  }

  public async getProfile(): Promise<Profile> {
    return await this.api.get<Profile>(`/accounts/brand/profile`);
  }

  public async getPaymentPreferences(): Promise<AccountPaymentPreferences> {
    return await this.api.get<AccountPaymentPreferences>(`/accounts/brand/settings/payments`);
  }

  public async updateAccount(account: AccountProfile): Promise<void> {
    return await this.api.patch<void>(`/accounts/brand`, account);
  }

  public async updateDeliveryPreferences(preferences: ApiDeliveryPreferences): Promise<void> {
    return await this.api.patch<void>(`/accounts/brand/delivery`, preferences);
  }

  public async updatePaymentPreferences(preferences: SetupPaymentPreferences): Promise<void> {
    return await this.api.put<void>(`/accounts/brand/settings/payments`, preferences);
  }
}

const AccountsApiInstance: IAccountsApi = new AccountsApi(apicoreApi);
export default AccountsApiInstance;