import { AppError, AppErrorCode } from "../adapters/apicoreapi/models";

export type UIErrorCompact = {
  message: string
  code?: AppErrorCode
}

export class UIError extends Error {
  private readonly appError: AppError | null;

  public static MSG_UNHANDLED_ERROR = "Щось трапилося, ми вже працюємо на виправленням цього. Ви також можете додатково повідомити нас про цю помилку";
  public static MSG_NETWORK_ERROR = "Помилка мережі. Перевірте інтрнет з'єднання та спробуйте ще раз";
  public static MSG_INTERNAL_SERVER_ERROR = "Щось не працює в данний момент, ми вже працюємо на виправленням цього. Ви також можете додатково повідомити нас про цю помилку";
  public static MSG_CONTRACT_ERROR = "Помилка відправки данних, ми вже працюємо на виправленням цього. Ви також можете додатково повідомити нас про цю помилку";
  public static MSG_UNAUTHORIZED_ERROR = "Помилка доступу. Будь ласка, перезавантажте сторінку та спробуйте ще раз";

  constructor(message: string, appError: AppError | null) {
    super(message);
    this.appError = appError;

    if(message === UIError.MSG_UNHANDLED_ERROR) {
      console.trace(`Unhandled error: ${message}`);
    }
  }

  static fromError(error: unknown, uiMessageBuilder?: (appError: AppError) => string | undefined): UIError {
    const isUiError = error instanceof UIError;
    const isAppError = error instanceof AppError;
    let message = UIError.MSG_UNHANDLED_ERROR;

    if(isUiError) {
      return error
    }

    // Default error parsing based on AppErrorCode
    if(isAppError) {
      if (error.code === AppErrorCode.SpecificError) {
        if (error.message === "ERR_NETWORK") {
          message = UIError.MSG_NETWORK_ERROR;
        }
      } else if (error.code === AppErrorCode.InternalServerError) {
        message = UIError.MSG_INTERNAL_SERVER_ERROR;
      } else if (error.code === AppErrorCode.UnprocessableEntity) {
        message = UIError.MSG_CONTRACT_ERROR;
      } else if (error.code === AppErrorCode.Unauthorized) {
        message = UIError.MSG_UNAUTHORIZED_ERROR;
      }

      // Apply custom error handling if uiMessageBuilder is provided
      if (uiMessageBuilder) {
        const customMessage = uiMessageBuilder(error);
        if (customMessage) {
          message = customMessage;
        }
      }
    }

    return new UIError(
      message,
      isAppError ? error : null
    );
  }

  public getMessage(): string {
    return this.message;
  }

  public getAppError(): AppError | null {
    return this.appError;
  }

  public getCompact(): UIErrorCompact {
    return {
      code: this.appError?.code,
      message: this.message
    }
  }

  public static getUnhandledErrorMessage(): string {
    return this.MSG_UNHANDLED_ERROR;
  }
}