import { SnackBarInfo } from "../../../components/SnackBar/SnackBarInfo";
import { hideSnackBar, showSnackBar } from "./slice";
import { AppDispatch } from "../../../store/store";

export const showSnackBarWithTimeoutThunk = (info: SnackBarInfo, timeout: number = 5000) => async (dispatch: AppDispatch) => {
  // Dispatch the action to show the snackbar
  dispatch(showSnackBar(info));

  // Set a timeout to hide the snackbar after the specified time
  setTimeout(() => {
    dispatch(hideSnackBar());
  }, timeout);
};
