import { css, styled } from "styled-components";

export const Title = styled.div`
    color: #32302B;
    font-size: 0.875rem;
    font-weight: 300;
    
    &:hover {
        cursor: pointer;
    }
`;

export const Description = styled.div<{$active: boolean}>`
    font-size: 0.813rem;
    font-weight: 300;
    line-height: 1rem;

    color: #808080;

    ${({ $active }) => $active && css`
        color: #32302B;
    `};
`;