import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackBarInfo } from "../../../components/SnackBar/SnackBarInfo";
import { ThemeType } from 'lisa/common/styles/theme';

type UiState = {
  theme: ThemeType;
  snackBar: {
    info: SnackBarInfo;
    visible: boolean;
  };
};

const initialState: UiState = {
  theme: ThemeType.Light, 
  snackBar: {
    info: {
      title: "",
      description: "",
      actionName: "",
      isError: false,
    },
    visible: false,
  }
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showSnackBar(state, action: PayloadAction<SnackBarInfo>) {
      state.snackBar.info = action.payload;
      state.snackBar.visible = true;
    },
    hideSnackBar(state) {
      state.snackBar.visible = false;
    },
    changeTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload;
    },
  },
});

export const { showSnackBar, hideSnackBar, changeTheme } = uiSlice.actions;

export default uiSlice.reducer;
