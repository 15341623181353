export enum PaymentMode {
  DuringOrder = "during_order",
  AfterShipping = "after_shipping",
  Partial = "partial",
}

export const PaymentModes = new Map([
  [PaymentMode.DuringOrder, "Під час замовлення"],
  [PaymentMode.AfterShipping, "Після плата"],
  [PaymentMode.Partial, "Часткова оплата"],
]);
