import { IBillingApi } from "./api";
import { BillingEmail } from "./models/BillingEmail";
import { BillingPlan, BillingPreferences } from "./models/BillingPreferences";

class BillingFakeApi implements IBillingApi {
  public async fetchBillingPreferences(): Promise<BillingPreferences> {
    return Promise.resolve({
      email: "email@example.com",
      plan: BillingPlan.Base,
      nextBillingDate: new Date().toISOString(),
    });
  }

  public async startTrial(): Promise<void> {
    return Promise.resolve();
  }

  public async startSubscription(plan: string): Promise<void> {
    return Promise.resolve();
  }

  public async cancelSubscription(): Promise<void> {
    return Promise.resolve();
  }

  public async updateBillingPreferences(email: BillingEmail): Promise<void> {
    return Promise.resolve();
  }
}

export { BillingFakeApi };
