import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIErrorCompact } from "lisa/common/store/UIError";
import { error, Loadable, LoadableStatus, loading } from "../../../../common/store/Loadable";
import { BillingEmail } from "../../billing/adapters/billingapi/models/BillingEmail";
import { PaymentMode } from "../../../../customer/features/feed/adapters/models/PaymentMode";

export type ProfilePreferencesForm = {
  displayName?: string;
  media?: string;
  coverMedia?: string;
  instagramUsername: string;
  tiktokUsername?: string;
  private: boolean;
  billingEmail: BillingEmail;
  playgroundMode: boolean;
  paymentMode: PaymentMode;
  paymentPartialAmount: number | undefined;
  paymentPartialIsRefundable: boolean | undefined;
  orderCancelPolicy: string | undefined;
}

type ShopPreferencesState =  Loadable<ProfilePreferencesForm>

export const initialShopState: ShopPreferencesState = loading({
  instagramUsername: "",
  private: true,
  billingEmail: "",
  playgroundMode: false,
  paymentMode: PaymentMode.DuringOrder,
  paymentPartialAmount: undefined,
  paymentPartialIsRefundable: undefined,
  orderCancelPolicy: undefined,
});

const shopPreferencesSlice = createSlice({
  name: "shopPrefs",
  initialState: initialShopState,
  reducers: {
    updateShopPreferences(state) {
      state.status = LoadableStatus.Loading;
    },
    updateShopPreferencesSuccess(state, action: PayloadAction<ProfilePreferencesForm>) {
      state.status = LoadableStatus.Success;
      state.error = undefined;
      state.data = {...state.data, ...action.payload};
    },
    updateShopPreferencesError(state, action: PayloadAction<UIErrorCompact>) {
      state.status = LoadableStatus.Error;
      state.error = action.payload;
      state.data = {...state.data};
    }
  }
});

export const {
  updateShopPreferences,
  updateShopPreferencesSuccess,
  updateShopPreferencesError,
} = shopPreferencesSlice.actions;

export default shopPreferencesSlice.reducer;