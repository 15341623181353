import React, { Fragment, ReactNode, useRef, useState } from "react";
import { SeoHeader } from "../../components";
import {
  accountValidationSchema,
  createAccountApiValidation,
} from "./validation";
import { Formik, FormikProps } from "formik";
import {
  BackButtonContainer,
  backButtonStyles,
  BaseContainer,
  Container,
  PopupContainer,
  TextContainer,
} from "./styles";
import { Input } from "lisa/common/styles/components/Form";
import { AccentButton, BackButton } from "lisa/common/styles/components/Button";
import { AppErrorCode } from "../../adapters/apicoreapi/models";
import { Error } from "lisa/common/styles/components/Form/Error";
import { UIError, UIErrorCompact } from "../../store/UIError";
import AuthenticationApiInstance from "./adapters/rest";
import { useNavigate } from "react-router-dom";
import { SessionStorageAdapter } from "../session/adapters/SessionStorageAdapter";
import { Gap } from "lisa/common/styles/components/Flex";
import { Heading } from "../../styles/components/Typography/Heading";
import { useMediaQuery } from "react-responsive";
import { Text } from "lisa/common/styles/components/Typography/Text";
import SignInHeader from "./components/SignInHeader";
import { InstagramIcon } from "lisa/common/icons/InstagramIcon";
import { useBilling } from "lisa/merchant/features/billing/store/thunks";
import Instagram from "../../../../components/view/landing/svg/instagram";

// TODO: move it to the models
type State = {
  loading: boolean;
  error: UIErrorCompact | null;
  username: string;
  instagram: string;
};
const initialState: State = {
  loading: false,
  error: null,
  username: "",
  instagram: "",
};

const CreateAccountPage = () => {
  const navigate = useNavigate();
  const featureFlagBilling = useRef<boolean>(
    process.env.REACT_APP_FEATURE_FLAG_BILLING === "true"
  );
  const spottedFeatureFlag = useRef<boolean>(
    process.env.REACT_APP_SPOTTED_FEATURE_FLAG === "true"
  ).current;

  const [state, setState] = useState<State>(initialState);
  const initialFormValues = {
    username: "",
    instagram: "",
  };

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const { checkFreeTrial } = useBilling();

  // On submit user will create a new account and navigate to the next page
  const submitForm = async (username: string, instagram: string) => {
    setState({ ...state, loading: true });

    try {
      await AuthenticationApiInstance.createAccount(username, instagram);
      setState({ ...state, loading: false });

      // Update global state
      SessionStorageAdapter.setAccountComplete();

      if (featureFlagBilling.current) {
        await checkFreeTrial();
      }

      // Redirect to app home page
      navigate("/lisa/merchant", { replace: true });
    } catch (error) {
      const uiError = UIError.fromError(error, createAccountApiValidation);
      setState({ ...state, loading: false, error: uiError.getCompact() });
    }
  };

  const renderError = (): ReactNode => {
    return state.error !== null ? <Error text={state.error.message} /> : null;
  };

  const logOut = () => {
    SessionStorageAdapter.removeAll();
    navigate("/");
  };

  return (
    <Fragment>
      <SeoHeader
        title="Новий акаунт"
        description={spottedFeatureFlag ? "Новий акаунт Spotted" : "Новий акаунт Brandlink"}
        url="/merchant/welcome/v2"
        imageUrl="/logo.png"
      />
      <main>
        <SignInHeader />
        <Container>
          <PopupContainer $desktop={isDesktop}>
            <BaseContainer>
              <BackButtonContainer>
                <BackButton
                  tid={"back-btn"}
                  kind="big"
                  onClick={logOut}
                  customStyle={backButtonStyles}
                />
              </BackButtonContainer>
              <Gap $gap={60}>
                <TextContainer>
                  <Gap $gap={12}>
                    <Heading as="h1" $size="medium">
                      Створіть акаунт
                    </Heading>
                    <Text $size="small" $color="signInDescription">
                      Вкажіть останні деталі і ми відразу створимо простір для
                      вашого бренду
                    </Text>
                  </Gap>
                </TextContainer>
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={accountValidationSchema}
                  onSubmit={(values) =>
                    submitForm(values.username, values.instagram)
                  }
                  validateOnBlur
                >
                  {({
                    handleSubmit,
                    isValid,
                    dirty,
                    values,
                  }: FormikProps<typeof initialFormValues>) => (
                    <form onSubmit={handleSubmit}>
                      <Gap $gap={8}>
                        <Input
                          tid="username"
                          name="username"
                          placeholder="Унікальний username"
                          type="string"
                          PrefixElement="@"
                          prefixGap={4}
                          autoFocus
                        />
                        <Input
                          tid="instagram"
                          name="instagram"
                          placeholder="Instagram бренду ( username )"
                          type="string"
                          PrefixElement={<Instagram />}
                          prefixGap={8}
                        />
                        {renderError()}
                        <AccentButton
                          tid={"create-account-btn"}
                          title="Створити"
                          disabled={
                            !isValid ||
                            !dirty ||
                            state.error?.code === AppErrorCode.TooManyRequests
                          }
                          loading={state.loading}
                          type="submit"
                          fullWidth
                        />
                        <TextContainer $width="full">
                          <Text $size="tiny" $color="signInDescription">
                            {values.username
                              ? `Ваш простір буде доступним за адресою ${spottedFeatureFlag ? `spotted.so/${values.username}` : `brandlink.app/${values.username}`}`
                              : `Ваш простір буде доступним за адресою ${spottedFeatureFlag ? "spotted.so/username" : "brandlink.app/username"}`}
                          </Text>
                        </TextContainer>
                      </Gap>
                    </form>
                  )}
                </Formik>
              </Gap>
            </BaseContainer>
          </PopupContainer>
        </Container>
      </main>
    </Fragment>
  );
};

export default CreateAccountPage;
