import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIError, UIErrorCompact } from "lisa/common/store/UIError";
import {
  error,
  LoadableStatus,
  loading,
  success,
} from "../../../../common/store/Loadable";
import { initialState } from "./ListingsStoreState";
import { Thumbnail, ListingList } from "../adapters/listingsapi/models/Listing";
import {
  Product,
  ProfileListing,
} from "../adapters/listingsapi/models/ProfileListing";
import { UpdatedListing } from "../adapters/listingsapi/models/UpdatedListing";
import { AppErrorCode } from "lisa/common/adapters/apicoreapi/models";
import {
  ListingVariations,
  VariationOptions,
} from "../adapters/listingsapi/models/Variations";
import { MediaAssetArray } from "../../../../../kit/MediaEditor/MediaAsset/MediaAssetArray";

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    fetchListings(state) {
      state.listings.status = LoadableStatus.Loading;
      state.listings.error = undefined;
    },
    fetchListingsSuccess(
      state,
      action: PayloadAction<{ page: number; listings: ListingList }>
    ) {
      // It's important to skip the same page, cause in React StrictMode the app does double fetch
      if (state.page == action.payload.page) {
        return;
      }

      state.listings = success({
        products: [
          ...state.listings.data.products,
          ...action.payload.listings.products,
        ],
      });
      state.page = action.payload.page;
      state.listings.error = undefined;
      state.listings.status = LoadableStatus.Success;
      state.hasMorePages = action.payload.listings.products.length > 0;
    },
    fetchListingsError(state, action: PayloadAction<UIErrorCompact>) {
      state.listings = error({ ...state.listings.data }, action.payload);
    },
    fetchListing(state) {
      state.activeListing.status = LoadableStatus.Loading;
      state.activeListing.error = undefined;
    },
    fetchListingSuccess(state, action: PayloadAction<ProfileListing>) {
      state.activeListing = success(action.payload);
      state.activeListing.status = LoadableStatus.Success;
      state.newProduct.data = {
        id: "",
        options: {
          available: [],
          choose: [],
        },
        price: 0,
        stock: 0,
        assets: new MediaAssetArray(),
      }
    },
    fetchListingError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeListing = error(
        { ...state.activeListing.data },
        action.payload
      );
    },
    deleteListing(state) {
      state.listings.status = LoadableStatus.Loading;
      state.listings.error = undefined;
    },
    deleteListingSuccess(state, action: PayloadAction<string>) {
      state.listings.data.products = state.listings.data.products.filter(
        (listing) => listing.listingId !== action.payload
      );
      state.listings.status = LoadableStatus.Success;
      state.listings.error = undefined;
    },
    deleteListingError(state, action: PayloadAction<UIErrorCompact>) {
      state.listings.status = LoadableStatus.Error;
      state.listings.error = action.payload;
    },
    updateListing(state, action: PayloadAction<UpdatedListing>) {
      state.activeListing.status = LoadableStatus.Loading;
      state.activeListing = loading({
        ...state.activeListing.data,
        ...action.payload,
      });
      state.activeListing.error = undefined;
    },
    updateListingSuccess(state) {
      state.activeListing.status = LoadableStatus.Success;
      state.activeListing.error = undefined;
    },
    updateListingError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeListing.status = LoadableStatus.Error;
      state.activeListing.error = action.payload;
    },
    updateProduct(state) {
      state.activeListing.status = LoadableStatus.Loading;
      state.activeListing.error = undefined;
    },
    updateProductSuccess(
      state,
      action: PayloadAction<{
        id: string;
        price?: number;
        stock?: number;
        primary?: boolean;
        sale?: number;
      }>
    ) {
      const product = state.activeListing.data.products.find(
        (product) => product.id === action.payload.id
      );

      if (!product) {
        state.activeListing.status = LoadableStatus.Error;
        state.activeListing.error = {
          message: UIError.getUnhandledErrorMessage(),
          code: AppErrorCode.InternalServerError,
        };
        return;
      }

      product.stock = action.payload.stock || product.stock;
      product.primary = action.payload.primary || product.primary;
      product.sale = action.payload.sale || product.sale;

      state.activeProduct.data = { ...product };

      state.activeListing.status = LoadableStatus.Success;
      state.activeListing.error = undefined;
      state.activeProduct.status = LoadableStatus.Success;
      state.activeProduct.error = undefined;
    },
    updateProductError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeListing.status = LoadableStatus.Error;
      state.activeListing.error = action.payload;
    },

    deleteProduct(state) {
      state.activeListing.status = LoadableStatus.Loading;
      state.activeListing.error = undefined;
    },
    deleteProductSuccess(
      state,
      action: PayloadAction<{ listingId: string; productId: string }>
    ) {
      const listing = state.activeListing.data.products.find(
        (listing) => listing.id === action.payload.listingId
      );
      if (listing) {
        state.activeListing.data.products =
          state.activeListing.data.products.filter(
            (product) => product.id !== action.payload.productId
          );

        state.activeListing.status = LoadableStatus.Success;
        state.activeListing.error = undefined;
      } else {
        console.error("Internal error: Listing not found.");
        state.activeListing.status = LoadableStatus.Error;
        state.activeListing.error = {
          message: UIError.getUnhandledErrorMessage(),
          code: AppErrorCode.InternalServerError,
        };
      }
    },
    deleteProductError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeListing.status = LoadableStatus.Error;
      state.activeListing.error = action.payload;
    },
    fetchProduct(state) {
      state.activeProduct.status = LoadableStatus.Loading;
      state.activeProduct.error = undefined;
    },
    fetchProductSuccess(state, action: PayloadAction<{ product: Product }>) {
      state.activeProduct.data = action.payload.product;
      state.activeProduct.status = LoadableStatus.Success;
      state.activeProduct.error = undefined;
    },
    fetchProductError(state, action: PayloadAction<UIErrorCompact>) {
      state.activeProduct.status = LoadableStatus.Error;
      state.activeProduct.error = action.payload;
      state.activeProduct.data = {
        price: 0,
        stock: 0,
        media: [],
        sale: 0,
        primary: false,
      };
    },
    getAvailableVariationOptions(state) {
      state.newProduct.status = LoadableStatus.Loading;
      state.newProduct.error = undefined;
    },
    getAvailableVariationOptionsSuccess(
      state,
      action: PayloadAction<{
        variation: ListingVariations;
        options: ListingVariations[];
      }>
    ) {
      const { variation, options } = action.payload;

      const variationExists = state.newProduct.data.options?.available.some(
        (existingVariation) => existingVariation.variation.id === variation.id
      );

      if (variationExists) {
        state.newProduct.status = LoadableStatus.Success;
        return;
      }

      const newOptions: VariationOptions = {
        variation: variation,
        options: options,
      };

      state.newProduct.status = LoadableStatus.Success;
      state.newProduct.data.options = {
        ...state.newProduct.data.options,
        available: [
          ...(state.newProduct.data.options?.available || []),
          newOptions,
        ],
      };
      state.newProduct.error = undefined;
    },
    getAvailableVariationOptionsError(
      state,
      action: PayloadAction<UIErrorCompact>
    ) {
      state.newProduct.status = LoadableStatus.Error;
      state.newProduct.error = action.payload;
    },
    selectListingVariationOption(state, action: PayloadAction<{ id: number; name: string; typeName: string }>) {
      const { id, name, typeName } = action.payload;

      const existingIndex = state.newProduct.data.options.choose.findIndex(
        (option) => option.id === id && option.name === name
      );

      if (existingIndex !== -1) {
        const newOptions = state.newProduct.data.options.choose.map((option, index) => {
          if (index === existingIndex) {
            return {
              ...option,
              typeName,
            };
          }
          return option;
        });

        state.newProduct.data.options.choose = newOptions;
      } else {
        state.newProduct.data.options.choose.push({ id, name, typeName });
      }

      state.newProduct.status = LoadableStatus.Success;
      state.newProduct.error = undefined;
    },
    unselectListingVariationOption(state, action: PayloadAction<{ id: number; name: string; typeName: string }>) {
      const { id, name, typeName } = action.payload;

      const filteredOptions = state.newProduct.data.options.choose.filter(
        (option) => !(option.id === id && option.name === name && option.typeName === typeName)
      );

      state.newProduct.data.options.choose = filteredOptions;
      state.newProduct.status = LoadableStatus.Success;
      state.newProduct.error = undefined;
    },
    addListingToTheList(
      state,
      action: PayloadAction<{ listing: Thumbnail }>
    ) {
      state.listings = success({
        products: [action.payload.listing,
          ...state.listings.data.products,
        ],
      });
    },
  },
});

export const {
  fetchListings,
  fetchListingsSuccess,
  fetchListingsError,
  fetchListing,
  fetchListingSuccess,
  fetchListingError,
  deleteListing,
  deleteListingSuccess,
  deleteListingError,
  updateListing,
  updateListingSuccess,
  updateListingError,
  updateProduct,
  updateProductSuccess,
  updateProductError,
  deleteProduct,
  deleteProductError,
  deleteProductSuccess,
  fetchProduct,
  fetchProductError,
  fetchProductSuccess,
  getAvailableVariationOptions,
  getAvailableVariationOptionsError,
  getAvailableVariationOptionsSuccess,
  selectListingVariationOption,
  unselectListingVariationOption,
  addListingToTheList
} = listingsSlice.actions;

export default listingsSlice.reducer;
