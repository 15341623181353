import { UIErrorCompact } from "./UIError";

export enum LoadableStatus {
  Empty = 'empty',
  Loading = 'loading',
  Success = 'success',
  Error = 'error'
}

export type Loadable<TData> =
  | { status: LoadableStatus.Empty; data: TData; error?: undefined }
  | { status: LoadableStatus.Loading; data: TData; error?: undefined }
  | { status: LoadableStatus.Success; data: TData; error?: undefined }
  | { status: LoadableStatus.Error; data: TData; error: UIErrorCompact };

export const empty = <TData>(data: TData): Loadable<TData> => ({
  status: LoadableStatus.Empty,
  data,
});

export const loading = <TData>(data: TData): Loadable<TData> => ({
  status: LoadableStatus.Loading,
  data,
});

export const success = <TData>(data: TData): Loadable<TData> => ({
  status: LoadableStatus.Success,
  data,
});

export const error = <TData>(data: TData, error: UIErrorCompact): Loadable<TData> => ({
  status: LoadableStatus.Error,
  error: error,
  data
});