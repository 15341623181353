import React from "react";
import styled from "styled-components";

type Props = {
  as?: React.ElementType;
  $size:
    | "default"
    | "normal"
    | "large"
    | "big"
    | "medium"
    | "mediumSmall"
    | "small"
    | "tiny";
  $color:
    | "black"
    | "grey"
    | "greyLight"
    | "headerMenu"
    | "noCollections"
    | "preferences"
    | "preferencesDescription"
    | "preferencesImageText"
    | "signInDescription"
    | "callout"
    | "hintError"
    | "checkoutSubmit";
  $style?: "underline" | "line-through" | "none";
};

export const Text = styled.div<Props>`
  cursor: default;
  color: ${({ $color }) => {
    switch ($color) {
      case "black":
        return "var(--text-100)";
      case "grey":
        return "var(--text-106)";
      case "headerMenu":
        return (props) => props.theme.text105;
      case "greyLight":
        return "#B4B4B4";
      case "noCollections":
        return "#9D9B96";
      case "preferences":
        return "#8D8B86";
      case "preferencesDescription":
        return "var(--text-109)";
      case "preferencesImageText":
        return "var(--text-111)";
      case "signInDescription":
        return "#9B9696";
      case "hintError":
        return "var(--bg-hint-error)";
      case "checkoutSubmit":
        return "#706C63";
      default:
        return "var(--text-100)";
    }
  }};
  font-weight: 400;
  font-size: ${({ $size }) => {
    switch ($size) {
      case "large":
        return "1.875rem"; // 30px
      case "big":
        return "1.5rem"; // 24px
      case "medium":
        return "1.25rem"; // 20px
      case "mediumSmall":
        return "1.0625rem"; // 17px
      case "default":
        return "1rem"; // 16px
      case "normal":
        return "0.95rem"; // 15.2px
      case "small":
        return "0.875rem"; // 14px
      case "tiny":
        return "0.813rem"; // 13px
    }
  }};
  text-decoration: ${({ $style }) => $style || "none"};
`;
