export enum PaymentOrderType {
  PaymentTypeNone = 1,
  PaymentTypePlata = 3,
}

export const OrderPaymentTypes = new Map([
  [PaymentOrderType.PaymentTypeNone, "Без інтеграції"],
  [PaymentOrderType.PaymentTypePlata, "Plata (Monobank)"],
]);

export enum PaymentStatus {
  Created = "created",
  Pending = "pending",
  Success = "success",
  Hold = "hold",
  Canceled = "canceled",
  Expired = "expired",
  Error = "error",
  InternalError = "internal_error"
}

export const OrderPaymentStatus = new Map([
  [PaymentStatus.Created, "Створено (не сплачено)"],
  [PaymentStatus.Pending, "В обробці"],
  [PaymentStatus.Success, "Сплачено"],
  [PaymentStatus.Hold, "Утримується"],
  [PaymentStatus.Canceled, "Відмінено"],
  [PaymentStatus.Expired, "Закінчився термін дії"],
  [PaymentStatus.Error, "Помилка"],
  [PaymentStatus.InternalError, "Помилка обробки"],
]);

export enum RefundStatus {
  Pending = "pending",
  Success = "success",
  Error = "error",
  InternalError = "internal_error"
}