// --------------------------------------------------------
// Models
// --------------------------------------------------------

// Definition of the state of the sign-in feature
export type AuthenticationDetails = {
  method: SignInMethod;
  step: SignInStep;
  challengeToken: string;
  sms: {
    phone: string;
    code: string;
  };
}

export enum SignInMethod {
  // When a user views the page, the initial step is to choose a method
  Initial,
  Sms
}

export enum SignInStep {
  // When a user chooses a method, the next step is to enter a phone number
  Phone,
  // When a user enters a phone number, the next step is to enter a verification
  CodeVerification
}

// --------------------------------------------------------
// Initial states
// --------------------------------------------------------
export const initialAuthenticationState: AuthenticationDetails = {
  method: SignInMethod.Initial,
  step: SignInStep.Phone,
  challengeToken: "",
  sms: {
    phone: "",
    code: ""
  },
};