import { useAppDispatch } from "lisa/common/store/hooks";
import { AppDispatch, AppState } from "lisa/common/store/store";
import { UIError } from "lisa/common/store/UIError";
import AccountsApiInstance from "../adapters/accountsapi/AccountsApi";
import {
  fetchPaymentPreferences,
  fetchPaymentPreferencesError,
  fetchPaymentPreferencesSuccess,
  fetchProfile,
  fetchProfileError,
  fetchProfileSuccess,
} from "./profileSlice";
import {
  ProfilePreferencesForm,
  updateShopPreferencesSuccess,
} from "./shopPreferencesSlice";
import { updateShopPreferencesThunk } from "./shopPreferencesThunks";
import { OrderPolicyPreferences } from "../models/OrderPolicyPreferences";
import { updateOrderPolicyPreferencesThunk } from "./deliveryPreferencesThunks";
import { mapApiToDeliveryPreferences } from "../adapters/accountsapi/models/ApiDeliveryPreferences";
import { PaymentPreferences } from "../models/PaymentPreferences";
import { updatePaymentPreferencesThunk } from "./paymentPreferencesThunks";
import { mapApiPaymentPreferences } from "../adapters/accountsapi/models/SetupPaymentPreferences";
import { updateOrderPolicyPreferencesSuccess } from "./deliveryPreferencesSlice";
import { updatePaymentPreferencesSuccess } from "./paymentPreferencesSlice";
import { fetchBillingPreferencesThunk } from "../../billing/store/thunks";
import { useCallback } from "react";

export const useProfile = () => {
  const dispatch = useAppDispatch();

  return {
    fetchProfile: useCallback(
      (enabledBillingFeatureFlag: boolean) => dispatch(fetchProfileThunk(enabledBillingFeatureFlag)),
      [dispatch]
    ),

    fetchPaymentPreferences: useCallback(
      () => dispatch(fetchPaymentPreferencesThunk()),
      [dispatch]
    ),

    updateShopPreferences: useCallback(
      (actualPrefs: ProfilePreferencesForm, changedPrefs: ProfilePreferencesForm, enabledBillingFeatureFlag: boolean) =>
        dispatch(updateShopPreferencesThunk(actualPrefs, changedPrefs, enabledBillingFeatureFlag)),
      [dispatch]
    ),

    updateOrderPolicyPreferences: useCallback(
      (prefs: OrderPolicyPreferences) => dispatch(updateOrderPolicyPreferencesThunk(prefs)),
      [dispatch]
    ),

    updatePaymentPreferences: useCallback(
      (prefs: PaymentPreferences) => dispatch(updatePaymentPreferencesThunk(prefs)),
      [dispatch]
    ),
  };
};

export const fetchProfileThunk = (enabledBillingFeatureFlag: boolean) => async (dispatch: AppDispatch, getState: () => AppState) => {
  dispatch(fetchProfile());

  try {
    const profile = await AccountsApiInstance.getProfile();
    dispatch(fetchProfileSuccess(profile));

    if (enabledBillingFeatureFlag) {
      dispatch(fetchBillingPreferencesThunk());
    }

    const billingEmail = getState().billing.prefs.data.email;

    // Update shop preferences
    dispatch(
      updateShopPreferencesSuccess({
        private: profile.account.private,
        displayName: profile.account.displayName,
        instagramUsername: profile.account.instagram,
        tiktokUsername: profile.account.tiktok,
        media: profile.account.media,
        coverMedia: profile.account.coverMedia,
        billingEmail: billingEmail,
        playgroundMode: profile.account.playgroundMode,
        paymentMode: profile.account.paymentMode,
        paymentPartialAmount: profile.account.paymentPartialAmount,
        paymentPartialIsRefundable: profile.account.paymentPartialIsRefundable,
        orderCancelPolicy: profile.account.orderCancelPolicy,
      })
    );

    // Update delivery preferences
    const orderPolicyPreferences = mapApiToDeliveryPreferences(profile);
    dispatch(updateOrderPolicyPreferencesSuccess(orderPolicyPreferences));
  } catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      // TODO: Implement custom error handling
      // if (appError.code === AppErrorCode.BadRequest) {
      //   return "Невірний формат телефону";
      // }
      return undefined;
    });
    dispatch(fetchProfileError(uiError.getCompact()));
  }
};

const fetchPaymentPreferencesThunk = () => async (dispatch: AppDispatch) => {
  dispatch(fetchPaymentPreferences());
  try {
    const preferences = await AccountsApiInstance.getPaymentPreferences();
    dispatch(fetchPaymentPreferencesSuccess(preferences));

    // Update payment preferences
    const paymentPreferences = mapApiPaymentPreferences(preferences);
    dispatch(updatePaymentPreferencesSuccess(paymentPreferences));
  } catch (error: unknown) {
    const uiError = UIError.fromError(error, (appError): string | undefined => {
      // TODO: Implement custom error handling
      // if (appError.code === AppErrorCode.BadRequest) {
      //   return "Невірний формат телефону";
      // }
      return undefined;
    });
    dispatch(fetchPaymentPreferencesError(uiError.getCompact()));
  }
};


