import { css } from "styled-components";
import { mixinFlexRowCenterVertical } from "../Flex";

/**
 * Reusable text style (font-size, weight, color).
 */
export const mixinText = css`
    font-size: 0.875rem;
    font-weight: 300;
    color: ${({ theme }) => theme.text100};

    @media screen and (max-width: 767px) {
        font-size: 1rem;
        //transform: scale(0.9);
        //transform-origin: left center;
    }
`;

/**
 * Handles changes to border-color depending on $focused/$error props.
 */
export const mixinFocusError = css<{ $focused: boolean; $error?: boolean }>`
  ${({ $focused, $error, theme }) => {
  if ($error) {
    return css`
        border-color: ${theme.error || "#aa0505"};
      `;
  }
  if ($focused) {
    return css`
        border-color: #1a1a1a;
      `;
  }
  return null;
}}
`;

/**
 * Container that often surrounds an input:
 * - Has a prefix icon or text
 * - Has potential for error
 */
export const mixinContainer = css<{
  $error?: boolean;
  $prefixGap?: number;
}>`
  ${mixinFlexRowCenterVertical};
  position: relative;
  z-index: 4;

  /* Gap for prefix icon/text. */
  gap: ${({ $prefixGap }) => $prefixGap || 0}px;
  padding-left: 24px;
  
  border: 0.8px solid ${({ $error, theme }) => ($error ? theme.error : "#C6C0C0")};
  border-radius: 12px;

  background-color: ${({ $error }) => ($error ? "#fff" : "#FFF")};
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
  }
`;