import styled, { css, CSSProp } from "styled-components";
import { BaseButton, mixinAccent, mixinBigButton } from "./Base";
import React, { ReactNode } from "react";
import { UniversalSpinner } from "../UniversalSpinner";
import Flex from "../Flex";

const Button = styled(BaseButton)`
  position: relative;
  ${mixinAccent};
  ${mixinBigButton};
  ${({ $sharp }) => $sharp && `border-radius: 12px`};
  ${({ $customStyles }) =>
    $customStyles &&
    css`
      ${$customStyles}
    `};
`;

const Text = styled.span<{$visible: boolean}>`
    ${({ $visible }) => $visible ? css`opacity: 1` : css`opacity: 0`};
    
    &:hover {
        cursor: pointer;
    }
`

const SpinnerWrapper = styled(Flex)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

type Props = {
  as?: React.ElementType;
  href?: string;
  type?: "button" | "submit" | "reset";
  title: string;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  tid?: string;
  onClick?: () => void;
  customStyles?: CSSProp;
  sharp?: boolean;
};

export const AccentButton = (props: Props) => {
  const {
    as,
    href,
    title,
    type,
    loading,
    disabled,
    fullWidth,
    onClick,
    customStyles,
    sharp,
  } = props;

  const RenderSpinner = (): ReactNode => (
    <SpinnerWrapper $align="center" $justify="center" $fullHeight $fullWidth>
      <UniversalSpinner size="small" fullPage={false} dark={false} />
    </SpinnerWrapper>
  )

  return (
    <Button
      data-test-id={props.tid}
      as={as || "button"}
      to={href}
      type={type}
      disabled={disabled}
      $fullWidth={fullWidth}
      $customStyles={customStyles}
      $sharp={sharp}
      onClick={onClick}
    >
      <Text $visible={!loading}>{title}</Text>
      {loading && RenderSpinner()}
    </Button>
  );
};
